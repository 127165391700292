@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

.tippy-box[data-theme~='default'] {
	background-color: var(--color-text-main);

	&[data-placement^='top'] > .tippy-arrow::before {
		border-top-color: var(--color-text-main);
		transform: scale(0.7);
	}

	&[data-placement^='bottom'] > .tippy-arrow::before {
		border-bottom-color: var(--color-text-main);
		transform: scale(0.7);
	}

	&[data-placement^='left'] > .tippy-arrow::before {
		border-left-color: var(--color-text-main);
		transform: scale(0.7);
	}

	&[data-placement^='right'] > .tippy-arrow::before {
		border-right-color: var(--color-text-main);
		transform: scale(0.7);
	}
}
