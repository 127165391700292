// LAYOUT COLOR
$color-layout-white: #ffffff;
$color-layout-background: #f7f8fa;
$color-layout-line-1: #edeef2;
$color-layout-line-2: #e4e6eb;

// FORM COLOR
$color-form-10: #f3f4f5;
$color-form-20: #d8dbe0;
$color-form-30: #c4c9d1;
$color-form-40: #a5adb8;

// TEXT COLOR
$color-text-main: #3a3c43;
$color-text-secondary: #6e7687;
$color-text-submenu: #414348;
$color-text-disable: #b6bcc9;
$color-text-subtitle: #262626;
$color-text-white: #ffffff;

// ICON COLOR
$color-icon-main: #505662;
$color-icon-secondary: #818898;
$color-icon-disable: #c4cad4;

// BASIC SHADOW
$stroke-shadow: 0px 0px 1px rgba(76, 93, 112, 0.3);

// STYLE SHADOWS
$shadow-popover: $stroke-shadow, 0px 4px 8px rgba(76, 93, 112, 0.3);
$shadow-panel: $stroke-shadow, 0px 2px 4px rgba(76, 93, 112, 0.24);
$shadow-floatingCard: $stroke-shadow, 0px 4px 6px rgba(76, 93, 112, 0.24);
$shadow-hoverCard: $stroke-shadow, 0px 8px 12px rgba(76, 93, 112, 0.32);
$shadow-modalWindow: $stroke-shadow, 0px 8px 12px rgba(76, 93, 112, 0.32);
