.en-field-large {
	width: 100%;
	max-width: 25rem;
}

.en-field-medium {
	width: 100%;
	max-width: 17.5rem;
}

.en-field-small {
	width: 100%;
	max-width: 12.5rem;
}

.en-field {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 1.5rem;

	.en-input {
		width: 100%;
	}

	> label {
		font-size: 0.875rem;
		font-weight: 500;
		color: var(--color-text-main);
		line-height: 1.25rem;
		position: relative;

		.en {
			color: var(--color-icon-secondary);
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}
	}

	/* не включаем спан, т.к. в нем может быть описание */
	& > label + *:not(span),
	& > span + *:not(span) {
		margin-top: 0.25rem;
	}

	label + .p-inputtextarea,
	span + .p-inputtextarea {
		margin-top: 0.25rem;
	}

	> span {
		font-size: 0.813rem;
		color: var(--color-text-secondary);
		line-height: 1.25rem;
	}

	> small {
		display: flex;
		align-items: center;
		overflow: hidden;
		font-size: 0.75rem;
		height: 1rem;

		margin-top: 0.5rem;
		color: #{$color-red-60};
		animation: valid-animation 0.2s ease-in-out;

		span {
			color: #{$color-red-60};
		}
	}
}

@keyframes valid-animation {
	0% {
		max-height: 0;
	}

	100% {
		max-height: 1rem;
	}
}

.en-input {
	height: 2.25rem;
	padding: 0 0.75rem;
	border-radius: 2px;
	border: 1px solid var(--color-form-30);
	font-size: 0.875rem;
	color: var(--color-text-main);
	transition: background-color 0.2s, color 0.2s, border-color 0.2s,
		box-shadow 0.2s;

	&:enabled:hover {
		border: 1px solid var(--color-form-40);
	}
	&:focus {
		outline: none;
	}
	&:enabled:focus {
		border: 1px solid var(--color60);
		box-shadow: 0 0 0 0.125rem var(--color10);
	}
	&:disabled {
		background: var(--color-form-10);
	}

	&.en-invalid,
	&.ng-dirty.ng-invalid {
		&:enabled:focus {
			box-shadow: 0 0 0 0.125rem #{$color-red-10};
		}
		&:enabled:hover {
			box-shadow: 0 0 0 0.125rem #{$color-red-10};
		}

		border-color: #{$color-red-60};
	}

	&.h32 {
		height: 2rem;
	}

	&.h28 {
		height: 1.75rem;
	}

	& > i:last-of-type {
		right: 0.125rem;
		color: rgba(255, 255, 255, 0.6);
	}
}

.en-inputgroup {
	display: flex;
	align-items: stretch;
	position: relative;
	width: 100%;
	.icon {
		position: absolute;
		top: -30%;
		right: 0;
	}
	.en-input {
		width: 100%;
		&:first-child:enabled:focus {
			border-radius: 2px 0 0 2px;
		}
		&:last-child:enabled:focus {
			border-radius: 0 2px 2px 0;
		}
		&:first-child {
			border-radius: 2px 0 0 2px;
		}
		&:last-child {
			border-radius: 0 2px 2px 0;
		}
	}
	.en-input ~ .icon {
		position: absolute;
		top: 20%;
		right: 2%;
		color: var(--color60);
	}
	.en-input ~ .en-icongroup {
		position: absolute;
		top: 20%;
		right: 2%;
		color: var(--color60);
	}
	.en-input-addon {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.813rem;
		padding: 0 0.5rem;
		color: var(--color-text-secondary);
		background: var(--color-form-10);
		border: 1px solid var(--color-form-30);

		&:first-child {
			border-right: none;
			border-radius: 2px 0 0 2px;
		}
		&:last-child {
			border-left: none;
			border-radius: 0 2px 2px 0;
		}
	}
}

.en-input-search {
	position: relative;
	display: inline-block;

	& > i {
		position: absolute;
		top: 50%;
		margin-top: -0.5rem;
	}

	& > i:first-of-type {
		color: var(--color-icon-secondary);
	}

	& > input {
		height: 2rem;
	}

	&.h36 > input {
		height: 2.25rem;
	}

	&.h40 > input {
		height: 2.5rem;
	}
}

.en-input-reset {
	position: relative;
	& > input {
		padding-right: 1.75rem;
	}
}
.en-input-reset {
	display: inline-block;
	.en-input-reset-icon {
		position: absolute;
		border: none;
		background: none;
		cursor: pointer;
		height: 100%;
		right: 0.25rem;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.2s ease-out;
	}

	.en-input-reset-icon--show {
		visibility: visible;
		opacity: 1;
		transition-timing-function: ease-in;
	}

	& > input {
		height: 2rem;
	}

	&.h40 > input {
		height: 2.5rem;
	}
}

.en-input-search.en-input-search-page {
	&.en-input-icon-left > i:first-of-type {
		left: 0.75rem;
	}

	&.en-input-icon-left > .en-input {
		padding-left: 0.75rem * 2 + 1rem;
	}

	&.en-input-icon-right > i:last-of-type {
		right: 0.75rem;
	}

	&.en-input-icon-right > .en-input {
		padding-right: 0.75rem * 2 + 1rem;
	}
}

.en-input-search.en-input-search-dialog {
	&.en-input-icon-left > i:first-of-type {
		left: 0.5rem;
	}

	&.en-input-icon-left > .en-input {
		padding-left: 0.5rem * 2 + 1rem;
	}
}

.en-input-success {
	display: inline-block;
	position: relative;

	&:hover {
		background-color: var(--color-form-10);
	}
	& > button {
		display: inline-block;
		background: none;
		border: none;
		position: absolute;
		height: 100%;
		cursor: pointer;
		right: 0.25rem;
		color: var(--color-form-20);
		transition: color 0.2s linear;
		&:hover {
			color: var(--color60);
		}
	}
	& > input {
		width: 100%;
		padding-right: 1.75rem;
	}
}

.p-inputtextarea-resizable {
	overflow: hidden;
	resize: none;
}
