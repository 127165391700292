@import './src/styles/prime/designer/_mixins';

.p-tree {
	.p-tree-filter-container {
		height: 2.5rem;
	}

	.p-tree-filter-container input {
		padding-left: 1.5rem;
		padding-top: 0.25rem;
		height: 100%;
	}

	.p-tree-filter-icon {
		display: none;
	}

	.p-tree-container,
	.p-tree-wrapper {
		height: 100%;
	}

	.p-treenode-label {
		margin-left: 0.5rem;
	}

	.p-treenode-selected > div:first-child {
		background-color: var(--color-layout-background);
		font-weight: bold;
	}

	.p-treenode-content {
		height: 3.75rem;
		display: flex;

		&:hover {
			background-color: var(--color-layout-background);
		}
	}

	button {
		color: var(--color-icon-secondary);
		border: none;
		background-color: inherit;
		width: 0.375rem;
		margin-left: 1rem;
	}

	.p-tree-toggler {
		height: 1.625rem;
		width: 1.625rem;
		transition: $formElementTransition;
		display: flex;
		align-items: center;
		border-radius: 0.75rem;
		margin-right: 0.375rem;

		&:hover {
			background-color: var(--color-layout-line-2);
		}
	}

	.pi-chevron-right {
		padding-left: 0.125rem;
	}

	.p-treenode-selected-child {
		color: var(--color60);
		font-weight: bold;
		background-color: var(--color10);
	}

	.p-treenode-child {
		padding-left: 3.5rem;

		&:hover {
			background-color: var(--color-layout-background);
		}

		div > button {
			display: none;
		}
	}
}
