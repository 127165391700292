@use 'sass:math';
@import './inputswitch';
$sphereSize: 1.375rem;

en-inputswitch {
	display: inline-flex;
}

.en-switch-field {
	display: flex;
	align-items: center;
	padding: 0.5rem 0;

	& > en-inputswitch + span {
		margin-left: 0.75rem;
	}
}

.en-inputswitch {
	position: relative;
	display: inline-block;
	height: 1.25rem;
	width: $sphereSize * 2;
}

.en-inputswitch-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.en-inputswitch-slider:before {
	position: absolute;
	content: '';
	top: 50%;
}
.en-switch-checkbox {
	//свойства основы
	outline-offset: 0.25rem;
	margin: 0;
	transform: translateY(0.2rem);

	&.focus-visible:focus + .en-inputswitch-slider {
		outline: auto;
	}
}

.en-inputswitch {
	.en-inputswitch-slider {
		//свойства основы
		background: $color-form-10;
		border: 1px solid $color-form-30;
	}
	.en-inputswitch-slider:before {
		//свойства шара
		background: $color-form-30;
		width: $sphereSize;
		height: $sphereSize;
		left: 0;
		margin-top: -(math.div($sphereSize, 2));
		border-radius: 50%;
		transition-duration: 0.2s;
		box-shadow: 0px 0px 0px 1px $color-form-30;
		border: 5px solid #ffffff;
	}

	&:not(.en-disabled):hover {
		.en-inputswitch-slider:before {
			//активный шар при наведении
			border-color: $color-form-10;
		}
	}

	&.en-inputswitch-checked {
		.en-inputswitch-slider {
			//активная основа
			background: $color-blue-10;
			border: 1px solid $color-blue-60;

			&:before {
				//активный шар
				background: $color-blue-60;
				box-shadow: 0px 0px 0px 1px $color-blue-60;
				transform: translateX($sphereSize - 0.125rem);
			}
		}

		&:not(.en-disabled):hover {
			.en-inputswitch-slider:before {
				//активный шар при наведении
				border-color: $color-blue-10;
			}
		}
	}
}

//disabled styles
.en-disabled.en-inputswitch {
	.en-inputswitch-slider {
		//свойства основы
		border: none;
		cursor: auto;
	}
	.en-inputswitch-slider:before {
		//свойства шара
		background: $color-form-20;
		box-shadow: 0px 0px 0px 1px $color-form-20;
		border: 5px solid #ffffff;
	}

	&:not(.en-disabled):hover {
		.en-inputswitch-slider:before {
			//активный шар при наведении
			border-color: $color-form-10;
		}
	}

	&.en-inputswitch-checked {
		.en-inputswitch-slider {
			//активная основа
			border: none;

			&:before {
				//активный шар
				background: $color-blue-20;
				box-shadow: 0px 0px 0px 1px $color-blue-20;
			}
		}
	}
}
