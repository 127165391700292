@import './prime_button.scss';

.en-button {
	display: inline-flex;
	height: 2.5rem;
	align-items: center;
	background: linear-gradient(
		180deg,
		var(--color60) 0,
		var(--color70) 2.5rem,
		var(--color80) 5rem,
		var(--color80) 7.5rem
	);
	background-size: auto 7.5rem;
	background-position: center -1px;
	transition: 0.2s background;
	color: var(--color-text-white);
	border: 1px solid transparent;
	font-weight: 500;
	flex-shrink: 0;

	&:hover {
		background-position: 5rem;
	}

	&:enabled:active {
		background-position: bottom;
	}
	&:disabled {
		border: 1px solid var(--color-form-20);
		background: linear-gradient(
			180deg,
			var(--color-layout-white) 0,
			var(--color-form-10) 2.5rem
		);
		color: var(--color-text-disable);

		.en-button-icon {
			color: var(--color-text-disable);
		}
	}
}

.en-button {
	&:not(.en-button-icon-only) {
		padding: 0 1.25rem;
	}
	&.en-button-icon-only {
		padding: 0 0.75rem;
	}
}

.en-button.en-button-table-link {
	background: none;
	padding: 0;
	height: auto;
	font-size: inherit;
	color: var(--color-blue-70);
	cursor: pointer;

	&:disabled {
		color: var(--color-text-disable);
		text-decoration: none;
		border: none;
	}
}

.en-button .en-button-icon {
	font-size: 1rem;
}

.icon20 .en-button .en-button-icon {
	font-size: 1.25rem;
}

.en-button[iconPos='left'] .en-button-icon,
en-button[iconPos='left'] .en-button-icon,
.en-button[iconPos='right'] .en-button-icon,
en-button[iconPos='right'] .en-button-icon {
	color: var(--color-text-white);
}

.en-button:disabled .en-button-icon {
	color: var(--color-icon-disable);
}

.en-button[iconPos='left'] .en-button-icon,
en-button[iconPos='left'] .en-button-icon {
	padding: 0 0.75rem 0 1rem;
}
.en-button[iconPos='right'] .en-button-icon,
en-button[iconPos='right'] .en-button-icon {
	padding: 0 1rem 0 0.75rem;
}

.en-button[iconPos='left'],
en-button[iconPos='left'] .en-button {
	padding: 0 1.25rem 0 0;
}
.en-button[iconPos='right'],
en-button[iconPos='right'] .en-button {
	padding: 0 0 0 1.25rem;
}
.en-button .en-button-icon-left {
	margin: 0;
}
.en-button .en-button-icon-right {
	margin: 0;
}

//iconpos="center"
.en-button.en-button-icon-only {
	width: 2.5rem;
}
.en-button.h36.en-button-icon-only {
	width: 2.25rem;
}
.en-button.h32.en-button-icon-only {
	width: 2rem;
}
.en-button.h28.en-button-icon-only {
	width: 1.75rem;
}

//styleClass="36"
.en-button.h36 {
	height: 2.25rem;
	background: linear-gradient(
		180deg,
		var(--color60) 0,
		var(--color70) 2.25rem,
		var(--color80) 4.5rem,
		var(--color80) 6.75rem
	);
	background-size: auto 6.75rem;
	background-position: center -1px;

	&.en-button-link,
	&.en-button-link:disabled {
		background: inherit;
		border: none;
	}
	&:hover {
		background-position: 4.5rem;
	}
	&:enabled:hover {
		background-position: 4.5rem;
	}

	&:enabled:active {
		background-position: bottom;
	}
	&:disabled {
		border: 1px solid var(--color-form-20);
		background: linear-gradient(
			180deg,
			var(--color-layout-white) 0,
			var(--color-form-10) 2.5rem
		);
		color: var(--color-text-disable);
	}
}

.en-button.h36 {
	&:not(.en-button-icon-only, .en-button-link) {
		padding: 0 1rem;
	}
	&.en-button-icon-only {
		padding: 0 0.625rem;
	}
}

.en-button[iconPos='left'].h36 .en-button-icon,
en-button[iconPos='left'] .h36 .en-button-icon {
	padding: 0 0.75rem;
}
.en-button[iconPos='right'].h36 .en-button-icon,
en-button[iconPos='right'] .h36 .en-button-icon {
	padding: 0 0.75rem;
}

.en-button[iconPos='left'].h36,
en-button[iconPos='left'] .en-button.h36 {
	padding: 0 1rem 0 0;
}
.en-button[iconPos='right'].h36,
en-button[iconPos='right'] .en-button.h36 {
	padding: 0 0 0 1rem;
}
//styleClass="36"

//styleClass="32"
.en-button.h32 {
	height: 2rem;
	background: linear-gradient(
		180deg,
		var(--color60) 0,
		var(--color70) 2rem,
		var(--color80) 4rem,
		var(--color80) 6rem
	);
	background-size: auto 6rem;
	background-position: center -1px;

	&.en-button-link,
	&.en-button-link:disabled {
		background: inherit;
		border: none;
	}
	&:hover {
		background-position: 4rem;
	}
	&:enabled:hover {
		background-position: 4rem;
	}

	&:enabled:active {
		background-position: bottom;
	}
	&:disabled {
		border: 1px solid var(--color-form-20);
		background: linear-gradient(
			180deg,
			var(--color-layout-white) 0,
			var(--color-form-10) 2.5rem
		);
		color: var(--color-text-disable);
	}
}

.en-button.h32 {
	&:not(.en-button-icon-only, .en-button-link) {
		padding: 0 0.75rem;
	}
	&.en-button-icon-only {
		padding: 0 0.625rem;
	}
}

.en-button[iconPos='left'].h32 .en-button-icon,
en-button[iconPos='left'] .h32 .en-button-icon {
	padding: 0 0.5rem;
}
.en-button[iconPos='right'].h32 .en-button-icon,
en-button[iconPos='right'] .h32 .en-button-icon {
	padding: 0 0.5rem;
}

.en-button[iconPos='left'].h32,
en-button[iconPos='left'] .en-button.h32 {
	padding: 0 0.75rem 0 0;
}
.en-button[iconPos='right'].h32,
en-button[iconPos='right'] .en-button.h32 {
	padding: 0 0 0 0.75rem;
}
//styleClass="h32"

//styleClass="h28"
.en-button.h28 {
	height: 1.75rem;
	font-size: 0.813rem;
	padding: 0 0.5rem;
	background: linear-gradient(
		180deg,
		var(--color60) 0,
		var(--color70) 1.75rem,
		var(--color80) 3.5rem,
		var(--color80) 5.25rem
	);
	background-size: auto 5.25rem;
	background-position: center -1px;

	&.en-button-link,
	&.en-button-link:disabled {
		background: inherit;
		border: none;
	}
	&:hover {
		background-position: 3.5rem;
	}
	&:enabled:hover {
		background-position: 3.5rem;
	}

	&:enabled:active {
		background-position: bottom;
	}
	&:disabled {
		border: 1px solid var(--color-form-20);
		background: linear-gradient(
			180deg,
			var(--color-layout-white) 0,
			var(--color-form-10) 2.5rem
		);
		color: var(--color-text-disable);
	}
}

.en-button[iconPos='left'].h28 .en-button-icon,
en-button[iconPos='left'] .h28 .en-button-icon {
	padding: 0 0.25rem;
}
.en-button[iconPos='right'].h28 .en-button-icon,
en-button[iconPos='right'] .h28 .en-button-icon {
	padding: 0 0.25rem;
}

.en-button[iconPos='left'].h28,
en-button[iconPos='left'] .en-button.h28 {
	padding: 0 0.5rem 0 0;
}
.en-button[iconPos='right'].h28,
en-button[iconPos='right'] .en-button.h28 {
	padding: 0 0 0 0.5rem;
}
//styleClass="h28"

//styleClass="h24"
.en-button.h24 {
	height: 1.5rem;
	font-size: 0.75rem;
	background: linear-gradient(
		180deg,
		var(--color60) 0,
		var(--color70) 1.5rem,
		var(--color80) 3rem,
		var(--color80) 4.5rem
	);
	background-size: auto 4.5rem;
	background-position: center -1px;

	&.en-button-link,
	&.en-button-link:disabled {
		background: inherit;
		border: none;
	}
	&:hover {
		background-position: 3rem;
	}
	&:enabled:hover {
		background-position: 3rem;
	}

	&:enabled:active {
		background-position: bottom;
	}
	&:disabled {
		border: 1px solid var(--color-form-20);
		background: linear-gradient(
			180deg,
			var(--color-layout-white) 0,
			var(--color-form-10) 2.5rem
		);
		color: var(--color-text-disable);
	}
}
.en-button.h24:not(.en-button-icon-only, .en-button-link) {
	padding: 0 0.5rem;
}
.en-button[iconPos='left'].h24 .en-button-icon,
en-button[iconPos='left'] .h24 .en-button-icon {
	padding: 0 0.25rem 0 0.125rem;
}
.en-button[iconPos='right'].h24 .en-button-icon,
en-button[iconPos='right'] .h24 .en-button-icon {
	padding: 0 0.125rem 0 0.25rem;
}

.en-button[iconPos='left'].h24,
en-button[iconPos='left'] .en-button.h24 {
	padding: 0 0.5rem 0 0;
}
.en-button[iconPos='right'].h24,
en-button[iconPos='right'] .en-button.h24 {
	padding: 0 0 0 0.5rem;
}
//styleClass="h24"

//Link
.en-button.en-button-link {
	color: var(--color-blue-60);
	padding: 0;

	&[iconPos='left'],
	&[iconPos='right'] {
		padding: 0;
	}

	.en-button-icon {
		color: var(--color-blue-60);
	}

	&:hover {
		.en-button-label {
			text-decoration: underline;
			color: var(--color-blue-70);
		}
		.en-button-icon {
			color: var(--color-blue-70);
		}
	}

	&:disabled,
	&:disabled:hover .en-button-label,
	&:disabled .en-button-icon {
		color: var(--color-text-disable);
		text-decoration: none;
		border: none;
		background: inherit;
	}
}

//Secondary
.en-button.en-button-secondary {
	background: linear-gradient(
		180deg,
		var(--color-layout-white) 0,
		var(--color-form-20) 2.5rem,
		var(--color-form-30) 5rem,
		var(--color-form-30) 7.5rem
	);
	border: 1px solid var(--color-form-30);
	color: var(--color-text-main);
	background-size: auto 7.5rem;
	background-position: top;
	transition: 0.2s background;

	.en-button-icon {
		color: var(--color-text-main);
	}

	&:hover {
		background-position: 5rem;
	}
	&:enabled:hover {
		background-position: 5rem;
	}
	// &:enabled:focus {
	// 	background-position: 5rem;
	// }
	&:enabled:active {
		background-position: bottom;
	}
	&:active {
		background-position: bottom;
	}
	&:disabled {
		border: 1px solid var(--color-form-20);
		background: linear-gradient(
			180deg,
			var(--color-layout-white) 0,
			var(--color-form-10) 2.5rem
		);
		color: var(--color-text-disable);

		.en-button-icon {
			color: var(--color-text-disable);
		}
	}
}

//select button

.en-selectbutton .en-button {
	height: 2.25rem;
	padding: 0 0.75rem;
	background: linear-gradient(
		180deg,
		var(--color-layout-white) 0,
		var(--color-form-10) 2.5rem,
		var(--color-form-10) 5rem,
		var(--color-form-20) 7.5rem
	);
	border: 1px solid var(--color-form-30);
	color: var(--color-text-main);
	background-size: auto 7.5rem;
	background-position: top;
	transition: 0.2s background;
	font-size: 0.813rem;
	font-weight: 400;

	&:hover {
		background-position: 2.5rem;
	}
	&:enabled:hover {
		background-position: 5rem;
	}
	// &:enabled:focus {
	// 	background-position: 5rem;
	// }
	&:enabled:active {
		background-position: bottom;
	}
	&:active {
		background-position: bottom;
	}
	&:disabled {
		border: 1px solid var(--color-form-20);
		background: linear-gradient(
			180deg,
			var(--color-layout-white) 0,
			var(--color-form-10) 2.5rem
		);
		color: var(--color-text-disable);
	}
	&.disabled {
		border: 1px solid var(--color-form-20);
		background: linear-gradient(
			180deg,
			var(--color-layout-white) 0,
			var(--color-form-10) 2.5rem
		);
		color: var(--color-text-disable);
	}
}

.en-selectbutton .en-button.en-button-icon-only {
	padding: 0.375rem;
}
.en-selectbutton.h32 .en-button.en-button-icon-only {
	width: 2rem;
	padding: 0.5rem;
}

.en-selectbutton .en-button.en-highlight {
	background-position: bottom;
}
.en-selectbutton.h32 .en-button {
	height: 2rem;
}

.en-selectbutton.h28 .en-button {
	height: 1.75rem;
	padding: 0 0.5rem;
}

//Solid
.en-button.en-button-solid {
	background: $color-red-60;
	color: var(--color-text-white);
	border: none;

	&:hover {
		background: $color-red-70;
	}
	&:enabled:hover {
		background: $color-red-70;
	}

	&:enabled:active {
		background: $color-red-80;
	}
	&:disabled {
		border: 1px solid var(--color-form-20);
		background: var(--color-form-10);
		color: var(--color-text-disable);
	}
}

.en-button.en-button-solid .en-button-icon {
	color: var(--color-text-white);
}

//Lowercase
.en-button.en-button-lowercase {
	background: transparent;
	color: var(--color-text-main);
	border: none;

	.en-button-icon {
		color: var(--color-text-main);
	}

	&:hover {
		background: var(--color-form-10);
	}
	&:enabled:hover {
		background: var(--color-form-10);
	}

	&:enabled:active {
		background: var(--color-blue-10);
	}
	&:disabled {
		background: var(--color-form-10);
		color: var(--color-text-disable);

		.en-button-icon {
			color: var(--color-text-disable);
		}
	}
}

// Segments-button
.en-button.en-segments-button-icon {
	background: transparent;
	color: var(--color-icon-main);
	border: none;

	&:hover {
		background: var(--color10);
	}
}

.en-button .en-button-icon-left {
	margin: 0;
}
.en-button .en-button-icon-right {
	margin: 0;
}

.en-button-label {
	flex: 1 1 auto;
}

.en-button-icon-right {
	order: 1;
}

.en-button:disabled {
	cursor: default;
}

.en-button-icon-only {
	justify-content: center;
	&:disabled {
		.en {
			color: var(--color-icon-disable);
		}
	}
}

.en-button-icon-only .en-button-label {
	visibility: hidden;
	width: 0;
	flex: 0 0 auto;
}

.en-button-vertical {
	flex-direction: column;
}

.en-button-icon-bottom {
	order: 2;
}

.en-buttonset {
	display: flex;
	flex-wrap: nowrap;
}

.en-buttonset .en-button {
	margin: 0;
	cursor: pointer;
}

.en-buttonset .en-button:not(:last-child) {
	border-right: 0 none;
}

.en-buttonset .en-button:not(:first-of-type):not(:last-of-type) {
	border-radius: 0;
}

.en-buttonset .en-button:first-of-type {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.en-buttonset .en-button:last-of-type {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.en-buttonset .en-button:focus {
	position: relative;
	z-index: 1;
}

.en-button.en-autocomplete {
	height: auto;
	width: 2rem;
	border-left: 0;
	position: absolute;
	right: 0.063rem;
	top: 0.063rem;
	bottom: 0.063rem;
}

.en-button:not(:disabled) .en-button-icon.en {
	&.green-button-icon {
		color: var(--color-green-60);
	}
	&.red-button-icon {
		color: var(--color-red-60);
	}
	&.red-50-button-icon {
		color: var(--color-red-50);
	}
	&.gray-button-icon {
		color: var(--color-icon-secondary);
	}
	&.blue-button-icon {
		color: var(--color-blue-60);
	}
}
