@import './colors-interface.scss';
@import './colors-service.scss';

// Mandatory Designer Variables
@import './variables';

@import 'src/styles/prime/designer/_components';
@import 'src/styles/prime/_extensions';

// material themes
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~@angular/material/prebuilt-themes/pink-bluegrey.css';
@import '~@angular/material/prebuilt-themes/purple-green.css';

// ROOT
:root[data-theme^='light'] {
	// FORM COLOR
	--color-form-10: #{$color-form-10};
	--color-form-20: #{$color-form-20};
	--color-form-30: #{$color-form-30};
	--color-form-40: #{$color-form-40};

	// LAYOUT COLOR
	--color-layout-background: #{$color-layout-background};
	--color-layout-white: #{$color-layout-white};
	--color-layout-line-1: #{$color-layout-line-1};
	--color-layout-line-2: #{$color-layout-line-2};

	// TEXT COLOR
	--color-text-main: #{$color-text-main};
	--color-text-secondary: #{$color-text-secondary};
	--color-text-disable: #{$color-text-disable};
	--color-text-submenu: #{$color-text-submenu};
	--color-text-subtitle: #{$color-text-subtitle};
	--color-text-white: #{$color-text-white};

	// ICON COLOR
	--color-icon-main: #{$color-icon-main};
	--color-icon-secondary: #{$color-icon-secondary};
	--color-icon-disable: #{$color-icon-disable};

	// STYLE SHADOWS
	--shadow-popover: #{$shadow-popover};
	--shadow-panel: #{$shadow-panel};
	--shadow-floatingCard: #{$shadow-floatingCard};
	--shadow-hoverCard: #{$shadow-hoverCard};
	--shadow-modalWindow: #{$shadow-modalWindow};

	//BLUE COLOR
	--color-blue-10: #{$color-blue-10};
	--color-blue-20: #{$color-blue-20};
	--color-blue-30: #{$color-blue-30};
	--color-blue-50: #{$color-blue-50};
	--color-blue-60: #{$color-blue-60};
	--color-blue-70: #{$color-blue-70};
	--color-blue-80: #{$color-blue-80};

	//GREEN COLOR
	--color-green-10: #{$color-green-10};
	--color-green-20: #{$color-green-20};
	--color-green-60: #{$color-green-60};
	--color-green-70: #{$color-green-70};
	--color-green-80: #{$color-green-80};

	// CYAN COLOR
	--color-cyan-10: #{$color-cyan-10};
	--color-cyan-20: #{$color-cyan-20};
	--color-cyan-50: #{$color-cyan-50};
	--color-cyan-60: #{$color-cyan-60};
	--color-cyan-70: #{$color-cyan-70};
	--color-cyan-80: #{$color-cyan-80};

	// TEAL COLOR
	--color-teal-10: #{$color-teal-10};
	--color-teal-50: #{$color-teal-50};
	--color-teal-60: #{$color-teal-60};
	--color-teal-70: #{$color-teal-70};
	--color-teal-80: #{$color-teal-80};

	// RED COLOR
	--color-red-50: #{$color-red-50};
	--color-red-60: #{$color-red-60};
	--color-red-20: #{$color-red-20};
	--color-red-10: #{$color-red-10};

	// Rose COLOR
	--color-rose-50: #{$color-rose-50};
	--color-rose-60: #{$color-rose-60};
	--color-rose-70: #{$color-rose-70};
	--color-rose-80: #{$color-rose-80};

	//AMBER COLOR
	--color-amber-10: #{$color-amber-10};
	--color-amber-20: #{$color-amber-20};
	--color-amber-50: #{$color-amber-50};
	--color-amber-60: #{$color-amber-60};
	--color-amber-70: #{$color-amber-70};

	--valid-border-color: #{$color-red-60};

	// COLOR GOLD
	--color-gold-rating: #{$color-gold-rating};

	// COLOR VIOLER
	--color-violet-10: #{$color-violet-10};
	--color-violet-60: #{$color-violet-60};
}

:root[data-theme='light-default'] {
	--color10: #{$color-blue-10};
	--color20: #{$color-blue-20};
	--color50: #{$color-blue-50};
	--color60: #{$color-blue-60};
	--color70: #{$color-blue-70};
	--color80: #{$color-blue-80};
}
