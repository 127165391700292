//loading
$loader-border-width: 2px;
$loader-size: 20px;

button.en-loading-button,
.en-loading-button button {
	pointer-events: none;
	user-select: none;
	opacity: 0.75;
	transition: opacity 100ms ease-in-out;
}

.en-loading-button {
	color: transparent;
	position: relative;
	cursor: progress;

	& * {
		opacity: 0;
	}

	&:after {
		content: ' ';
		position: absolute;
		top: calc(50% - #{$loader-size}/ 2);
		left: calc(50% - #{$loader-size}/ 2);
		display: inline-block;
		width: $loader-size;
		height: $loader-size;
		border: $loader-border-width solid var(--color20);
		border-radius: 50%;
		border-top-color: transparent;
		animation: spin 1s linear infinite;
		-webkit-animation: spin 1s linear infinite;
	}
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
