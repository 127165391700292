@import './prime_accordion.scss';
@import './icons.scss';

.en-accordion {
	padding: 0 0.25rem;
}
.en-accordion-header-link {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	user-select: none;
	position: relative;
	text-decoration: none;
	padding: 1.25rem;
	color: $color-text-main;
	border-radius: 2px;

	&.icon-mod {
		justify-content: flex-start;
	}

	&.size-mod {
		padding: 1.5rem;
	}

	.en-accordion-toggle-icon {
		color: $color-icon-secondary;
	}
}
.en-accordion-header-link:hover {
	.en-accordion-toggle-icon {
		color: $color-icon-main;
	}
}
.en-accordion-header-link:focus {
	z-index: 1;
}
.en-accordion-content {
	padding: 1.25rem;

	&.icon-mod {
		padding: 1.25rem 1.25rem 1.25rem 5rem;
	}
	&.size-mod {
		padding: 1.5rem;
	}
	&.icon-mod.size-mod {
		padding: 1.5rem 1.5rem 1.5rem 5.25rem;
	}
}
.en-accordion-header-text {
	font-size: 1.125rem;
	font-weight: 500;
	display: flex;
	align-items: center;
}

.en-accordion-left-icon {
	background-image: url('/assets/images/MCC/icons.svg');
	background-repeat: no-repeat;
	width: 40px;
	height: 40px;
	margin-right: 1.25rem;
}

.en-accordion-border {
	box-shadow: $shadow-panel;
	transition: box-shadow 0.2s ease, border-color 0.2s ease;
	border-radius: 2px;
	margin-bottom: 1rem;
	border: 1px solid transparent;

	&.opened {
		box-shadow: $shadow-floatingCard;

		&.icon-mod {
			border-color: var(--color60);
		}
	}
	&:hover {
		box-shadow: $shadow-floatingCard;
	}
	&.en-disabled {
		&:hover {
			box-shadow: $shadow-panel;
		}
	}
}

.en-disabled {
	.en-accordion-header-link {
		color: $color-text-disable;

		&:hover {
			.en-accordion-toggle-icon {
				color: $color-icon-secondary;
			}
		}
	}
	.en-accordion-left-icon {
		opacity: 0.5;
	}
}

.custom-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 1.25rem;
}
