button {
	font-family: var(--en-font-family);
	margin: 0;
	display: inline-flex;
	cursor: pointer;
	user-select: none;
	align-items: center;
	vertical-align: bottom;
	text-align: center;
	overflow: hidden;
	position: relative;
	color: var(--color-text-main);
}

input {
	font-family: var(--en-font-family);
}

h1 {
	margin: 0;
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.5rem;
}

h2 {
	margin: 0;
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.5rem;
}

h3 {
	margin: 0;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5rem;
}

h4 {
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
}

h5 {
	margin: 0;
	font-size: 0.875rem;
	font-weight: 700;
	line-height: 1.25rem;
	letter-spacing: 0.016rem;
}

h6 {
	margin: 0;
	font-size: 0.875rem;
	font-weight: 700;
	line-height: 1rem;
	letter-spacing: 0.016rem;
}

a {
	color: var(--color-text-main);
	text-decoration: none;
}

iframe {
	width: 100%;
	border: none;
}

.en-card {
	padding: 1.5rem;
	background: var(--color-layout-white);
	box-shadow: var(--shadow-panel);
	border-radius: 2px;
}

.en-text-small {
	display: inline-block;
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 1rem;
}

.en-text-medium {
	display: inline-block;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.25rem;
}

.en-text-large {
	display: inline-block;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
}

.en-text-extra-large {
	display: inline-block;
	font-size: 1.75rem;
	font-weight: 400;
	line-height: 2rem;
}

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	white-space: nowrap;
	border: 0;
	clip: rect(0 0 0 0);
	-webkit-clip-path: inset(100%);
	clip-path: inset(100%);
}

.text-overflow {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.en-mr-1 {
	margin-right: 0.25rem;
}

.en-mr-2 {
	margin-right: 0.5rem;
}

.en-mr-3 {
	margin-right: 0.75rem;
}

.en-mr-4 {
	margin-right: 1rem;
}

.en-mr-5 {
	margin-right: 1.25rem;
}

.font-weight-500 {
	font-weight: 500;
}

.text-color-badge {
	display: inline-block;
	background: var(--color10);
	border: 1px solid var(--color60);
	border-radius: 2px;
	padding: 0 0.5rem;
	height: 1.5rem;
	line-height: 1.375rem;
}

.en-text-secondary {
	color: var(--color-text-secondary);
}

.en-text-disable {
	color: var(--color-text-disable);
}

// table-tools
.tools-box {
	position: absolute;
	right: 0;
	bottom: 0;
	height: 3.063rem;
	transition: opacity 0.2s ease-out, transform 0.2s ease-out,
		border 0.2s ease-out;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	opacity: 0;
	background-color: var(--color-layout-background);
	transform: translateX(10px);
	border: 1px solid transparent;
	padding: 0 1rem;

	&.show {
		opacity: 1;
		transform: translateX(0);
		z-index: 2;
	}

	&.inactive {
		top: -9999px !important;
	}

	&:hover {
		border-color: var(--color-form-20);
	}
}

.empty-table {
	padding: 8rem 0;
	text-align: center;
}

.editable-view {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	padding: 0 0.75rem;
	height: 2rem;
	transition: background 0.2s;

	.en {
		transition: color 0.2s;
	}

	&:hover {
		background: var(--color-form-10);

		.en {
			color: var(--color60);
		}
	}

	&.h28 {
		height: 1.75rem;
	}
}

.en-multiselect-items {
	display: flex;
	flex-wrap: wrap;
}

.en-multiselect-item-label:not(:last-child) {
	margin-bottom: 0.125rem;
	margin-right: 0.125rem;
}

.en-tab-counter {
	font-weight: normal;
	border-radius: 0.5rem;
	background-color: var(--color-icon-secondary);
	color: var(--color-text-white);
	padding: 0 0.25rem;
	margin-left: 0.5rem;
}
