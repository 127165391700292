// GREEN COLOR
$color-green-10: #e8faee;
$color-green-20: #b4facb;
$color-green-30: #87f5ab;
$color-green-40: #56e887;
$color-green-50: #33d66a;
$color-green-60: #1ebd53;
$color-green-70: #16a847;
$color-green-80: #12993f;

// TEAL COLOR
$color-teal-10: #e8faf6;
$color-teal-20: #baf7e7;
$color-teal-30: #8ef5d9;
$color-teal-40: #5de8c3;
$color-teal-50: #35dbaf;
$color-teal-60: #1dc296;
$color-teal-70: #15ad85;
$color-teal-80: #129975;

// CYAN COLOR
$color-cyan-10: #e8f8fa;
$color-cyan-20: #b6f1fa;
$color-cyan-30: #8ee7f5;
$color-cyan-40: #5dd5e8;
$color-cyan-50: #3ac9df;
$color-cyan-60: #22a6ba;
$color-cyan-70: #1897ab;
$color-cyan-80: #158394;

// BLUE COLOR
$color-blue-10: #eaf3fb;
$color-blue-20: #d2e6f9;
$color-blue-30: #b1d0fc;
$color-blue-40: #4e95f8;
$color-blue-50: #1c63f6;
$color-blue-60: #234ec4;
$color-blue-70: #153eb5;
$color-blue-80: #0e2e8c;

// VIOLET COLOR
$color-violet-10: #f6f0fc;
$color-violet-20: #debefa;
$color-violet-30: #cb96fa;
$color-violet-40: #b76df7;
$color-violet-50: #a345f5;
$color-violet-60: #8619e5;
$color-violet-70: #7210c7;
$color-violet-80: #6513ad;

// PINK COLOR
$color-pink-10: #fcf0fa;
$color-pink-20: #fabef2;
$color-pink-30: #fa96ed;
$color-pink-40: #f76de5;
$color-pink-50: #f545dd;
$color-pink-60: #e519ca;
$color-pink-70: #c912b1;
$color-pink-80: #b0139b;

// ROSE COLOR
$color-rose-10: #faeef2;
$color-rose-20: #fabed2;
$color-rose-30: #fa96b7;
$color-rose-40: #f76d9b;
$color-rose-50: #f5457f;
$color-rose-60: #e01b5d;
$color-rose-70: #c3104b;
$color-rose-80: #ad1347;

// RED COLOR
$color-red-10: #fcf1f0;
$color-red-20: #fac2be;
$color-red-30: #fa9d96;
$color-red-40: #f7766d;
$color-red-50: #f55045;
$color-red-60: #e52719;
$color-red-70: #c71c10;
$color-red-80: #ad1d13;

// ORANGE COLOR
$color-orange-10: #faf1eb;
$color-orange-20: #fad3b9;
$color-orange-30: #fabb91;
$color-orange-40: #f7a168;
$color-orange-50: #f2873f;
$color-orange-60: #eb6913;
$color-orange-70: #c75910;
$color-orange-80: #ad5113;

// AMBER COLOR
$color-amber-10: #f7f2eb;
$color-amber-20: #fae1bb;
$color-amber-30: #f7ce8f;
$color-amber-40: #f5bd69;
$color-amber-50: #eba742;
$color-amber-60: #cc8925;
$color-amber-70: #bd7c1a;
$color-amber-80: #a36d1c;

// GOLD COLOR
$color-gold-rating: #f0de3b;
