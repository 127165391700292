/* Icons from directory "" */
.icon-msg-api--gray,
.icon-msg-api--gray\:regular {
	background-position: 0 0;
}

.icon-msg-api,
.icon-msg-api\:regular {
	background-position: 0 -40px;
}

.icon-msg-event--gray,
.icon-msg-event--gray\:regular {
    background-position: 0 -160px;
}

.icon-msg-event,
.icon-msg-event\:regular {
    background-position: 0 -200px;
}

.icon-msg-planned--gray,
.icon-msg-planned--gray\:regular {
	background-position: 0 -320px;
}

.icon-msg-planned,
.icon-msg-planned\:regular {
	background-position: 0 -360px;
}

.icon-msg-regular--gray,
.icon-msg-regular--gray\:regular {
	background-position: 0 -240px;
}

.icon-msg-regular,
.icon-msg-regular\:regular {
	background-position: 0 -280px;
}

.icon-msg-standart--gray,
.icon-msg-standart--gray\:regular {
	background-position: 0 -400px;
}

.icon-msg-standart,
.icon-msg-standart\:regular {
	background-position: 0 -440px;
}

.icon-msg-doi--gray,
.icon-msg-doi--gray\:regular {
  background-position: 0 -80px;
}

.icon-msg-doi,
.icon-msg-doi\:regular {
  background-position: 0 -120px;
}
