$textMarginRight: 1.5rem;

.en-toast-item {
	display: inline-flex;
	padding: 0.75rem 1rem 0.75rem 0.75rem;
	filter: drop-shadow(0.063rem 0.275rem 0.188rem rgba(76, 93, 112, 0.3));
	margin: 0 0 0.5rem 0;
	border-radius: 2px;
}

.en-toast--flexable {
	display: flex;
	.en-toast-icon {
		top: 50%;
	}
	.en-toast-closed {
		top: 50%;
	}
	.en-toast-detail-text--flexable {
		margin: 0 0 0 0.5rem;
	}
}

.en-toast-content {
	position: relative;
	padding: 0 4rem 0 0;
}

.en-message-flexable {
	flex-direction: row;
}

.en-toast-close {
	display: flex;
	align-items: center;
	justify-content: center;
}

.en-toast-close.en-link {
	margin-left: auto;
	overflow: hidden;
	position: relative;
}

.en-message {
	border-radius: 2px;
	display: inline-block;
}

.en-toast-icon {
	top: 26%;
	position: absolute;
	transform: translateY(-50%);
	font-size: 1rem;
}

.en-toast-info {
	background: var(--color-blue-10);
	border-left: 4px solid var(--color-blue-60);
	.en-toast-icon {
		color: var(--color-blue-60);
	}
}

.en-message-question {
	.en-message-icon {
		color: var(--color-blue-60);
	}
}

.en-toast-success {
	background: var(--color-green-10);
	border-left: 4px solid var(--color-green-60);
	.en-toast-icon {
		color: var(--color-green-60);
	}
}

.en-toast-error {
	background: var(--color-red-10);
	border-left: 4px solid var(--color-red-60);
	.en-toast-icon {
		color: var(--color-red-60);
	}
}

.en-toast-warn {
	background: var(--color-amber-10);
	border-left: 4px solid var(--color-amber-60);
	.en-toast-icon {
		color: var(--color-amber-60);
	}
}

.en-toast-summary-text {
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 600;
	margin: 0 0 0 $textMarginRight;
	color: var(--color-text-main);
}

.en-toast-detail-text {
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: var(--color-text-secondary);
	margin: 0 0 0 $textMarginRight;
}

.en-toast-closed {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0rem;
	top: 0rem;
	top: 25%;
	transform: translateY(-50%);
	border: none;
	background: none;
	border-radius: 50%;
	font-size: 0.75rem;
	width: 1.5rem;
	height: 1.5rem;
	font-weight: 700;
	cursor: pointer;
	&--info {
		&:hover {
			background: var(--color-blue-10);
			opacity: 0.6;
		}
	}
	&--warn {
		&:hover {
			background: var(--color-amber-10);
			opacity: 0.6;
		}
	}
	&--error {
		&:hover {
			background: var(--color-red-10);
			opacity: 0.6;
		}
	}
	&--success {
		&:hover {
			background: var(--color-green-10);
			opacity: 0.6;
		}
	}
}
