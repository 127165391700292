@font-face {
	font-family: 'en-icon';
	src: url('/assets/fonts/icon/en-icon.ttf?egkk0s') format('truetype'),
		url('/assets/fonts/icon/en-icon.woff?egkk0s') format('woff'),
		url('/assets/fonts/icon/en-icon.svg') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.en {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'en-icon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 1rem;
	color: var(--color-icon-main);

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* replace all '.en-' на '.en.en-' */

.en.en-amp-lighting:before {
	content: '\e989';
}
.en.en-dinamic-code:before {
	content: '\e98a';
}
.en.en-menu-help:before {
	content: '\e97a';
}
.en.en-menu-admin:before {
	content: '\e976';
}
.en.en-menu-data:before {
	content: '\e977';
}
.en.en-menu-mailings:before {
	content: '\e978';
}
.en.en-menu-main:before {
	content: '\e979';
}
.en.en-menu-notification:before {
	content: '\e980';
}
.en.en-menu-popup:before {
	content: '\e981';
}
.en.en-menu-recom:before {
	content: '\e982';
}
.en.en-menu-reports:before {
	content: '\e983';
}
.en.en-menu-settings:before {
	content: '\e984';
}
.en.en-menu-lock:before {
	content: '\e985';
}
.en.en-menu-unlock:before {
	content: '\e986';
}
.en.en-earth-o:before {
	content: '\e975';
}
.en.en-apple:before {
	content: '\e93c';
}
.en.en-android:before {
	content: '\e96f';
}
.en.en-hand:before {
	content: '\e949';
}
.en.en-spiner:before {
	content: '\e92e';
}
.en.en-cancel-circle:before {
	content: '\e900';
}
.en.en-check-circle-o:before {
	content: '\e901';
}
.en.en-done-circle-o:before {
	content: '\e902';
}
.en.en-exclamation-circle-o:before {
	content: '\e903';
}
.en.en-exclamation-triangle-o:before {
	content: '\e904';
}
.en.en-help-circle-o:before {
	content: '\e905';
}
.en.en-info-circle-o:before {
	content: '\e906';
}
.en.en-minus-circle-o:before {
	content: '\e907';
}
.en.en-pause-circle-o:before {
	content: '\e908';
}
.en.en-play-circle-o:before {
	content: '\e909';
}
.en.en-plus-circle-o:before {
	content: '\e90a';
}
.en.en-stop-circle-o:before {
	content: '\e90b';
}
.en.en-autoincrement-plus:before {
	content: '\e90c';
}
.en.en-boolean-type:before {
	content: '\e90d';
}
.en.en-date:before {
	content: '\e90e';
}
.en.en-datetime-type:before {
	content: '\e90f';
}
.en.en-email:before {
	content: '\e910';
}
.en.en-float-type:before {
	content: '\e911';
}
.en.en-number-type:before {
	content: '\e912';
}
.en.en-phone-type:before {
	content: '\e913';
}
.en.en-text-type:before {
	content: '\e914';
}
.en.en-arrow-down:before {
	content: '\e915';
}
.en.en-arrow-left:before {
	content: '\e916';
}
.en.en-arrow-right:before {
	content: '\e917';
}
.en.en-arrow-up:before {
	content: '\e918';
}
.en.en-block:before {
	content: '\e919';
}
.en.en-cancel:before {
	content: '\e91a';
}
.en.en-cancel-fill:before {
	content: '\e91b';
}
.en.en-chevron-double-left:before {
	content: '\e91c';
}
.en.en-chevron-double-right:before {
	content: '\e91d';
}
.en.en-chevron-down:before {
	content: '\e91e';
}
.en.en-chevron-left:before {
	content: '\e91f';
}
.en.en-chevron-right:before {
	content: '\e920';
}
.en.en-chevron-up:before {
	content: '\e921';
}
.en.en-close:before {
	content: '\e922';
}
.en.en-done:before {
	content: '\e923';
}
.en.en-drag-indicator:before {
	content: '\e924';
}
.en.en-ellipsis-v:before {
	content: '\e925';
}
.en.en-plus:before {
	content: '\e926';
}
.en.en-sort:before {
	content: '\e927';
}
.en.en-sort-bottom:before {
	content: '\e928';
}
.en.en-sort-down:before {
	content: '\e929';
}
.en.en-sort-left:before {
	content: '\e92a';
}
.en.en-sort-right:before {
	content: '\e92b';
}
.en.en-sort-top:before {
	content: '\e92c';
}
.en.en-sort-up:before {
	content: '\e92d';
}
.en.en-clear:before {
	content: '\e92f';
}
.en.en-basket:before {
	content: '\e930';
}
.en.en-editor-block:before {
	content: '\e931';
}
.en.en-mailing-groups:before {
	content: '\e932';
}
.en.en-chart-bar:before {
	content: '\e933';
}
.en.en-click:before {
	content: '\e934';
}
.en.en-clock:before {
	content: '\e935';
}
.en.en-save-cloud:before {
	content: '\e936';
}
.en.en-editor-html:before {
	content: '\e937';
}
.en.en-swap:before {
	content: '\e938';
}
.en.en-toggles:before {
	content: '\e939';
}
.en.en-copy:before {
	content: '\e93a';
}
.en.en-datafield:before {
	content: '\e93b';
}
.en.en-desktop:before {
	content: '\e93d';
}
.en.en-dollar:before {
	content: '\e93e';
}
.en.en-download:before {
	content: '\e93f';
}
.en.en-pencil-o:before {
	content: '\e940';
}
.en.en-emoji:before {
	content: '\e941';
}
.en.en-sign-out:before {
	content: '\e942';
}
.en.en-collapse:before {
	content: '\e943';
}
.en.en-expand:before {
	content: '\e944';
}
.en.en-eye:before {
	content: '\e945';
}
.en.en-filter:before {
	content: '\e946';
}
.en.en-folder-o:before {
	content: '\e947';
}
.en.en-gear-o:before {
	content: '\e948';
}
.en.en-image:before {
	content: '\e94a';
}
.en.en-key:before {
	content: '\e94b';
}
.en.en-link:before {
	content: '\e94c';
}
.en.en-list-o:before {
	content: '\e94d';
}
.en.en-list:before {
	content: '\e94e';
}
.en.en-upload:before {
	content: '\e94f';
}
.en.en-lock:before {
	content: '\e950';
}
.en.en-mail:before {
	content: '\e951';
}
.en.en-map:before {
	content: '\e952';
}
.en.en-eye-close:before {
	content: '\e953';
}
.en.en-no-link:before {
	content: '\e954';
}
.en.en-external-link:before {
	content: '\e955';
}
.en.en-open-mail:before {
	content: '\e956';
}
.en.en-paperclip:before {
	content: '\e957';
}
.en.en-mobile:before {
	content: '\e958';
}
.en.en-redo:before {
	content: '\e959';
}
.en.en-refrash:before {
	content: '\e95a';
}
.en.en-save-o:before {
	content: '\e95b';
}
.en.en-search:before {
	content: '\e95c';
}
.en.en-segment:before {
	content: '\e95d';
}
.en.en-columns:before {
	content: '\e95e';
}
.en.en-sort1:before {
	content: '\e95f';
}
.en.en-star-full-fill:before {
	content: '\e960';
}
.en.en-star-o:before {
	content: '\e961';
}
.en.en-tag:before {
	content: '\e962';
}
.en.en-template:before {
	content: '\e963';
}
.en.en-trash:before {
	content: '\e964';
}
.en.en-undo:before {
	content: '\e965';
}
.en.en-not-lock:before {
	content: '\e966';
}
.en.en-user:before {
	content: '\e967';
}
.en.en-web:before {
	content: '\e968';
}
.en.en-zoom-in:before {
	content: '\e969';
}
.en.en-zoom-out:before {
	content: '\e96a';
}
.en.en-art:before {
	content: '\e96b';
}
.en.en-refresh:before {
	content: '\e96c';
}
.en.en-mobpush:before {
	content: '\e96e';
}
.en.en-popup:before {
	content: '\e970';
}
.en.en-sms:before {
	content: '\e96d';
}
.en.en-telegram:before {
	content: '\e971';
}
.en.en-viber:before {
	content: '\e972';
}
.en.en-push:before {
	content: '\e973';
}
.en.en-whatsapp:before {
	content: '\e974';
}
.en.en-untag:before {
	content: '\e97b';
}
.en.en-tablet:before {
	content: '\e97c';
}
.en.en-pause:before {
	content: '\e97d';
}
.en.en-play:before {
	content: '\e97e';
}
.en.en-back:before {
	content: '\e97f';
}
.en.en-next:before {
	content: '\e987';
}
.en.en-book:before {
	content: '\e988';
}
.en.en-list-type:before {
	content: '\e98b';
}
