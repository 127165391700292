.p-dialog {
	border-radius: 4px;
	box-shadow: $overlayContainerShadow;
	border: $overlayContentBorder;

	.p-dialog-header {
		height: $dialogHeaderHeight;
		border-bottom: $dialogHeaderBorder;
		background: $dialogHeaderBg;
		color: $dialogHeaderTextColor;
		// padding: $dialogHeaderPadding;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;

		.p-dialog-title {
			font-weight: $dialogHeaderFontWeight;
			font-size: $dialogHeaderFontSize;
			line-height: $dialogHeaderLineHeight;
		}

		.p-dialog-header-icon {
			@include action-icon();
			margin-right: $inlineSpacing;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.p-dialog-content {
		flex: 1;
		background: $overlayContentBg;
		color: $panelContentTextColor;
		// padding: $dialogContentPadding;
	}

	.p-dialog-footer {
		// border-top: $dialogFooterBorder;
		// background: $overlayContentBg;
		color: $panelFooterTextColor;
		// padding: $dialogFooterPadding;
		text-align: right;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;

		button {
			// margin: 0 $inlineSpacing 0 0;
			width: auto;
		}
	}

	& > div:last-child {
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	&.p-confirm-dialog {
		.p-confirm-dialog-icon {
			font-size: $primeIconFontSize * 2;
		}

		// .p-confirm-dialog-message {
		// margin-left: $inlineSpacing * 2;
		// }
	}
}

.p-dialog-mask.p-component-overlay {
	background-color: $maskBg;
}
