$textMarginRight: 1.5rem;

.en-message-wrapper {
	display: flex;
	flex-direction: column;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;

	&--border {
		border: 1px solid var(--color-layout-line-1);
	}
}

.en-message-flexable {
	flex-direction: row;
}

.en-message-close {
	display: flex;
	align-items: center;
	justify-content: center;
}

.en-message-close.en-link {
	margin-left: auto;
	overflow: hidden;
	position: relative;
}

.en-message {
	width: 100%;
	border-radius: 2px;
	display: inline-block;

	.en-message-wrapper {
		position: relative;
		padding: 0.75rem 1rem 0.75rem 0.75rem;
	}
}

.en-message-icon {
	position: absolute;
	line-height: 1.25rem;
}

.en-message-info {
	.en-message-icon {
		color: #{$color-blue-60};
	}
}

.border-info {
	background: var(--color-layout-background);
	border-left: 4px solid #{$color-blue-60};
}

.en-message-question {
	.en-message-icon {
		color: #{$color-blue-60};
	}
}

.border-question {
	background: var(--color-layout-background);
	border-left: 4px solid #{$color-blue-60};
}

.en-message-success {
	.en-message-icon {
		color: #{$color-green-60};
	}
}

.border-success {
	background: var(--color-layout-background);
	border-left: 4px solid #{$color-green-60};
}

.en-message-warn {
	.en-message-icon {
		color: #{$color-amber-60};
	}
}

.border-warn {
	background: var(--color-layout-background);
	border-left: 4px solid #{$color-amber-60};
}

.en-message-error {
	.en-message-icon {
		color: #{$color-red-60};
	}
}

.border-error {
	background: var(--color-layout-background);
	border-left: 4px solid #{$color-red-60};
}

.en-message-summary {
	position: relative;
}
.en-message-summary-text {
	display: inline-block;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 600;
	margin: 0 0 0 $textMarginRight;
	color: var(--color-text-main);
}

.en-message-detail-text {
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: var(--color-text-secondary);
	margin: 0 0 0 $textMarginRight;
	&--flexable {
		margin: 0 0 0 0.5rem;
	}
}
