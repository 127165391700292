.en-accordion {
    .en-accordion-header {
        .en-accordion-header-link {
            background: $color-layout-white;
            transition: $listItemTransition;
        }
    }
    .en-accordion-content {
        background: $color-layout-white;
        color: $accordionContentTextColor;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }
}
