$tableBorderRadius: 2px;
.p-datatable {
	border: 1px solid var(--color-layout-line-1);
	border-radius: $tableBorderRadius;
	margin-top: 1rem;

	.p-component-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: $tableBorderRadius;
	}

	.p-paginator-top {
		border-width: $tableTopPaginatorBorderWidth;
		border-radius: 0;
	}

	.p-paginator-bottom {
		border-width: $tableBottomPaginatorBorderWidth;
		border-radius: 0;
	}

	.p-datatable-header {
		background: $tableHeaderBg;
		color: $tableHeaderTextColor;
		border: $tableHeaderBorder;
		border-width: $tableHeaderBorderWidth;
		padding: $tableHeaderPadding;
		font-weight: $tableHeaderFontWeight;
	}

	.p-datatable-footer {
		background: $tableFooterBg;
		color: $tableFooterTextColor;
		border: $tableFooterBorder;
		border-width: $tableFooterBorderWidth;
		padding: $tableFooterPadding;
		font-weight: $tableFooterFontWeight;
	}

	.p-datatable-thead > tr > th {
		text-align: $tableCellContentAlignment;
		padding: 1rem;
		border: $tableHeaderCellBorder;
		border-width: 0 1px 1px 0;
		font-weight: 400;
		color: $tableHeaderCellTextColor;
		background: $tableHeaderCellBg;
		transition: $listItemTransition;

		text-transform: uppercase;
		font-size: 0.813rem;
		line-height: 1rem;
	}

	.p-datatable-thead > tr:first-child > th:first-child {
		border-top-left-radius: $tableBorderRadius;
	}

	.p-datatable-thead > tr:first-child > th:last-child {
		border-right: 0px;
		border-top-right-radius: $tableBorderRadius;
	}

	.p-datatable-tbody > tr:last-child > td:first-child {
		border-bottom-left-radius: $tableBorderRadius;
	}

	.p-datatable-tbody > tr:last-child > td:last-child {
		border-right: 0px;
		border-bottom-right-radius: $tableBorderRadius;
	}

	.p-datatable-tbody > tr:last-child > td {
		border-bottom: 0px;
	}

	.p-datatable-tfoot > tr > td {
		text-align: $tableCellContentAlignment;
		padding: $tableFooterCellPadding;
		border: $tableFooterCellBorder;
		border-width: $tableFooterCellBorderWidth;
		font-weight: $tableFooterCellFontWeight;
		color: $tableFooterCellTextColor;
		background: $tableFooterCellBg;
	}

	.p-datatable-thead > tr > th.p-reorderable-column {
		padding-left: 1.25rem;
		// cursor: pointer;

		.p-reorderable-column-icon {
			position: absolute;
			left: 0.063rem;
		}

		.position-static.p-reorderable-column-icon {
			position: static;
		}
	}

	.p-datatable-thead > tr > th .en-header-column-wrapper {
		display: flex;
	}

	.p-sortable-column {
		outline-color: $focusOutlineColor;

		.en-header-column-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.p-sortable-column-icon {
			display: flex;
			width: 16px;
			height: 16px;
			// color: $tableHeaderCellIconColor;
			// margin-left: $inlineSpacing;
			position: relative;
			span {
				position: absolute;
			}

			span.en:not(.p-sortable-column-hightlight) {
				color: var(--color-icon-disable);
			}

			.p-sortable-column-hightlight {
				color: var(--color60);
			}
		}

		.p-sortable-column-badge {
			border-radius: 50%;
			height: $tableSortableColumnBadgeSize;
			min-width: $tableSortableColumnBadgeSize;
			line-height: $tableSortableColumnBadgeSize;
			color: $highlightTextColor;
			background: $highlightBg;
			margin-left: $inlineSpacing;
		}

		&:not(.p-highlight):hover {
			background: var(--color10);
			color: $tableHeaderCellTextHoverColor;

			.p-sortable-column-icon {
				color: $tableHeaderCellIconHoverColor;

				& > span:first-child.en {
					color: var(--color-text-secondary);
				}
			}
		}

		&.p-highlight {
			background: var(--color-form-10);
			// color: $tableHeaderCellHighlightTextColor;

			.p-sortable-column-icon {
				color: $tableHeaderCellHighlightTextColor;
			}
		}
	}

	.p-datatable-tbody {
		> tr {
			background: $tableBodyRowBg;
			color: $tableBodyRowTextColor;
			transition: $listItemTransition;
			outline-color: $focusOutlineColor;

			// FIX: сделать по нормальному
			&.add-column__row {
				height: 4.25rem;
			}

			> td {
				text-align: $tableCellContentAlignment;
				border: $tableBodyCellBorder;
				border-width: $tableBodyCellBorderWidth;
				padding: 0 1rem;
				height: 2.5rem;

				.p-row-toggler,
				.p-row-editor-init,
				.p-row-editor-save,
				.p-row-editor-cancel {
					@include action-icon();
				}

				.p-row-editor-save {
					margin-right: $inlineSpacing;
				}

				&.en-cell-no-padding {
					padding-top: 0;
					padding-bottom: 0;
				}
			}

			&.p-highlight {
				background: $highlightBg;
				color: $highlightTextColor;
			}

			&.p-datatable-dragpoint-top > td {
				box-shadow: inset 0 2px 0 0 $highlightBg;
			}

			&.p-datatable-dragpoint-bottom > td {
				box-shadow: inset 0 -2px 0 0 $highlightBg;
			}
		}
	}

	.p-datatable-tbody > tr > td.p-reorderable-column {
		padding-left: 1.25rem;

		&.p-row-expand-padding {
			padding-left: 1rem;
		}
	}

	&.p-datatable-hoverable-rows {
		.p-datatable-tbody > tr:not(.p-highlight):hover {
			background: var(--color-layout-background);
			color: $tableBodyRowTextHoverColor;
		}
	}

	.p-column-resizer-helper {
		background: $tableResizerHelperBg;
	}

	.p-datatable-scrollable-header,
	.p-datatable-scrollable-footer {
		background: $panelHeaderBg;
	}

	// .p-datatable-loading-icon {
	// 	width: 3.75rem;
	// 	height: 3.75rem;
	// 	border: 0.125rem solid var(--color-icon-disable);
	// 	border-radius: 50%;
	// 	border-top-color: var(--color-icon-main);
	// 	animation: spin 800ms ease-in-out infinite;

	// 	@keyframes spin {
	// 		to {
	// 			transform: rotate(360deg);
	// 		}
	// 	}
	// }

	&.p-datatable-gridlines {
		.p-datatable-header {
			border-width: 1px 1px 0 1px;
		}

		.p-datatable-footer {
			border-width: 0 1px 1px 1px;
		}

		.p-paginator-top {
			border-width: 0 1px 0 1px;
		}

		.p-paginator-bottom {
			border-width: 0 1px 1px 1px;
		}

		.p-datatable-thead {
			> tr {
				> th {
					border-width: 0 1px 1px 0;
				}
			}
		}

		.p-datatable-tbody {
			> tr {
				> td {
					border-width: 1px 1px 1px 0;
				}
			}
		}

		.p-datatable-tfoot {
			> tr {
				> td {
					border-width: 1px;
				}
			}
		}
	}

	&.p-datatable-striped {
		.p-datatable-tbody {
			> tr:nth-child(even) {
				background: $tableBodyRowEvenBg;

				&.p-highlight {
					background: $highlightBg;
					color: $highlightTextColor;

					.p-row-toggler {
						color: $highlightTextColor;

						&:hover {
							color: $highlightTextColor;
						}
					}
				}
			}
		}
	}

	&.p-datatable-sm {
		.p-datatable-header {
			@include scaledPadding($tableHeaderPadding, $tableScaleSM);
		}

		.p-datatable-thead > tr > th {
			@include scaledPadding($tableHeaderCellPadding, $tableScaleSM);
		}

		.p-datatable-tbody > tr > td {
			@include scaledPadding($tableBodyCellPadding, $tableScaleSM);
		}

		.p-datatable-tfoot > tr > td {
			@include scaledPadding($tableFooterPadding, $tableScaleSM);
		}

		.p-datatable-footer {
			@include scaledPadding($tableFooterPadding, $tableScaleSM);
		}
	}

	&.p-datatable-lg {
		.p-datatable-header {
			@include scaledPadding($tableHeaderPadding, $tableScaleLG);
		}

		.p-datatable-thead > tr > th {
			@include scaledPadding($tableHeaderCellPadding, $tableScaleLG);
		}

		.p-datatable-tbody > tr > td {
			@include scaledPadding($tableBodyCellPadding, $tableScaleLG);
		}

		.p-datatable-tfoot > tr > td {
			@include scaledPadding($tableFooterPadding, $tableScaleLG);
		}

		.p-datatable-footer {
			@include scaledPadding($tableFooterPadding, $tableScaleLG);
		}
	}
}
