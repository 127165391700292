.en-tooltip {
	position: absolute;
	display: none;
	padding: 0.5rem 0.5rem;
	max-width: 20rem;
	pointer-events: none;
	.en-tooltip-text {
		overflow: hidden;
		text-overflow: ellipsis;
		background: var(--color-text-main);
		color: var(--color-text-white);
		line-height: 1.25rem;
		padding: 0.5rem 1rem;
		box-shadow: var(--shadow-popover);
		border-radius: 4px;
	}
}

.en-tooltip-additional {
	color: var(--color-text-disable);
}

.en-tooltip.en-tooltip-right,
.en-tooltip.en-tooltip-left {
	padding: 0 0.5rem;
}

.en-tooltip .en-tooltip-text {
	white-space: pre-line;
}

.en-tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}

.en-tooltip-right .en-tooltip-arrow {
	top: 50%;
	border-width: 0.25rem 0.25rem 0.25rem 0;
	left: 0.25rem;
	border-right-color: var(--color-text-main);
	margin-top: -0.25rem;
	border-width: 0.25rem 0.25rem 0.25rem 0;
}

.en-tooltip-left .en-tooltip-arrow {
	top: 50%;
	right: 0.25rem;
	margin-top: -0.25rem;
	border-width: 0.25rem 0 0.25rem 0.25rem;
	border-left-color: var(--color-text-main);
}

.en-tooltip-top .en-tooltip-arrow {
	bottom: 0.25rem;
	left: 50%;
	transform: translateX(-50%);
	border-width: 0.25rem 0.25rem 0;
	border-top-color: var(--color-text-main);
}

.en-tooltip-bottom .en-tooltip-arrow {
	top: 0.25rem;
	left: 50%;
	transform: translateX(-50%);
	border-width: 0px 0.25rem 0.25rem;
	border-bottom-color: var(--color-text-main);
}

.en-tooltip-bottom .en-tooltip-arrow--left {
	left: 1.25rem;
}

.en-tooltip-bottom .en-tooltip-arrow--right {
	left: initial;
	right: 0.75rem;
}

.en-tooltip-top .en-tooltip-arrow--left {
	left: 1.25rem;
}
.en-tooltip-top .en-tooltip-arrow--right {
	left: initial;
	right: 0.75rem;
}

.alert__tooltip {
	font-size: 0.75rem;
}

.alert__tooltip {
	.en-tooltip-text {
		padding: 0.25rem 0.5rem;
	}
}
