// стили для списка карточек https://screenshots.expertsender.ru/valerij_seleznyov_IrJrlrJAfthN95pg.jpg
.setting-card {
	background-color: var(--color-layout-background);
	transition: background-color 0.2s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.75rem;
	border-radius: 4px;

	&.small {
		padding: 0 1rem;
		height: 2.25rem;
	}
	&.medium {
		padding: 0 1.5rem;
		height: 3.25rem;
	}

	.buttons {
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}

	&:hover {
		background-color: var(--color10);

		& .buttons {
			opacity: 1;
		}
	}
}
