.en-menu {
	&.mat-menu-panel {
		max-width: 100%;
		min-height: auto;
		background: var(--color-layout-white);
	}

	.mat-menu-content:not(:empty) {
		padding-top: 0;
		padding-bottom: 0.5rem;
	}

	.mat-menu-item {
		font-family: var(--en-font-family);
		font-size: 0.875rem;
		font-weight: 400;
		padding: 0 1rem;
		height: 2.5rem;
		line-height: 2.5rem;
		color: var(--color-text-main);
		background: var(--color-layout-white);
	}

	.mat-menu-item-icons {
		padding-left: 2.5rem;
	}

	.mat-menu-item-icon {
		position: absolute;
		top: 50%;
		left: 0.75rem;
		transform: translateY(-50%);
	}

	.mat-menu-item-submenu-trigger {
		padding-right: 1.75rem;
	}

	.mat-menu-item-submenu-trigger::after {
		position: absolute;
		font-family: 'en-icon';
		color: var(--color-icon-main);
		width: 16px;
		height: 16px;
		top: 50%;
		right: 0.5rem;
		content: '';
		border: none;
		transform: translateY(-50%);
		background-image: url('/assets/images/chevron-right.svg');
	}

	.mat-menu-item:disabled {
		background: var(--color-layout-background);
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		text-transform: uppercase;
		font-size: 0.813rem;
		line-height: 1rem;
		color: var(--color-text-secondary);
	}

	.mat-menu-item:disabled + .mat-menu-item:not(:first-child) {
		margin-top: 1rem;
	}

	.mat-menu-item:disabled:not(:first-child) {
		margin-top: 0.5rem;
	}

	.mat-menu-item:first-child:not([disabled]) {
		margin-top: 0.5rem;
	}

	.mat-menu-item-highlighted:not([disabled]),
	.mat-menu-item.cdk-keyboard-focused:not([disabled]),
	.mat-menu-item.cdk-program-focused:not([disabled]),
	.mat-menu-item:hover:not([disabled]) {
		background: var(--color-form-10);
	}

	&.mat-elevation-z4,
	&.mat-elevation-z5,
	&.mat-elevation-z6,
	&.mat-elevation-z7 {
		box-shadow: var(--shadow-popover);
	}

    &_mt-1 {
        margin-top: 0.25rem;
    }
}
