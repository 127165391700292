.en-togglebutton {
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
	border: 1px solid var(--color-form-10);
	border-radius: 2px;
	transition: 0.2s background, $formElementTransition;
	cursor: pointer;

	background: transparent;
	color: var(--color-text-disable);

	.en-button-icon-left,
	.en-button-icon-right {
		color: $toggleButtonIconColor;
    }

    .en-button-label {
        display: flex;
        justify-content: center;
    }

	&:not(.en-disabled):not(.en-highlight):hover {
		// background: var(--color-form-10);
        // color: var(--color-text-main);
	    // border: 1px solid var(--color-form-30);


		.en-button-icon-left,
		.en-button-icon-right {
			color: $toggleButtonIconHoverColor;
		}
	}

	&.en-highlight {
		// background: var(--color-form-10);
        color: var(--color-text-main);
        border: 1px solid var(--color-form-30);


		.en-button-icon-left,
		.en-button-icon-right {
			color: $toggleButtonIconActiveColor;
		}

		&:hover {
			// background: var(--color-form-20);

			.en-button-icon-left,
			.en-button-icon-right {
				color: $toggleButtonIconActiveHoverColor;
			}
		}
	}

	&.en-error,
	&.en-invalid {
		@include invalid-input();
	}
}

en-togglebutton.ng-dirty.ng-invalid > .en-togglebutton {
	@include invalid-input();
}
