/* You can add global styles to this file, and also import other style files */
@import 'styles/fonts/font.scss';
@import 'styles/fonts/font-icon.scss';

@import 'styles/box-model.scss';
@import 'styles/focus-visible.scss';

@import 'styles/en-extensions/extensions.scss';
@import 'styles/en-extensions/en-loading.scss';
@import 'styles/en-extensions/setting-card.scss';
@import 'styles/en-extensions/codemirror-scroll.scss';

@import 'styles/themes/light/theme.scss';

@import 'app/ui-lib/input/input.scss';
@import 'app/ui-lib/inputswitch/switch.scss';
@import 'app/ui-lib/button/button.scss';
@import 'app/ui-lib/accordion/accordion.scss';
@import 'app/ui-lib/menu/menu.scss';
@import 'app/ui-lib/messages/messages.scss';
@import 'app/ui-lib/toast/toast-item/toast-item.scss';
@import 'app/ui-lib/toast/toast.scss';
@import 'app/ui-lib/small-message/small-messages.scss';
@import 'app/ui-lib/badge-tag/badge.scss';
@import 'app/ui-lib/tool-tip//tooltip.scss';
@import 'app/ui-lib//helipoppper/helippoper-styles.scss';

// редактор кода
@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/material.css';
@import 'codemirror/addon/hint/show-hint.css';
@import 'codemirror/addon/dialog/dialog.css';

@import 'node_modules/@ctrl/ngx-emoji-mart/picker';

// Куда вынести?
.hidden-element {
	display: none;
	height: 0;
}

.app__body {
	.cdk-overlay-container {
		z-index: 999;
	}
}

tui-svg {
	height: 1rem !important;
	width: 1rem !important;
}

tui-dropdown-box {
	--tui-radius-m: 4px;
}
