.p-checkbox {
	//add
	margin-right: 0.25rem;

	display: inline-flex;
	cursor: pointer;
	user-select: none;
	vertical-align: bottom;

	.p-hidden-accessible {
		display: none;
	}

	.p-checkbox-box {
		background: $inputBg;

		width: 1rem;
		height: 1rem;

		display: flex;
		justify-content: center;
		align-items: center;

		//add
		border: 1px solid var(--color-form-30);

		color: $textColor;
		border-radius: $borderRadius;
		transition: $formElementTransition;

		.p-checkbox-icon {
			transition-duration: $transitionDuration;
			color: var(--color-text-white);
			font-size: 0.875rem;
		}

		&:not(.p-disabled):hover {
			border-color: $inputHoverBorderColor;
		}

		&:not(.p-disabled).p-focus {
			@include focused-input();
		}

		&.p-disabled {
			background: var(--color-form-10);
		}

		&.p-highlight {
			border-color: var(--color60);
			background: var(--color70);

			&:not(.p-disabled):hover {
				border-color: $checkboxActiveHoverBorderColor;
				background: $checkboxActiveHoverBg;
				color: $checkboxIconActiveHoverColor;
			}
		}
	}

	&.p-error > .p-checkbox-box,
	&.p-invalid > .p-checkbox-box {
		@include invalid-input();
	}
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
	@include invalid-input();
}

.p-input-filled {
	.p-checkbox {
		.p-checkbox-box {
			background-color: $inputFilledBg;

			&:not(.p-disabled):hover {
				background-color: $inputFilledHoverBg;
			}

			&.p-highlight {
				background: $checkboxActiveBg;

				&:not(.p-disabled):hover {
					background: $checkboxActiveHoverBg;
				}
			}
		}
	}
}

.p-checkbox-label {
	margin-left: $inlineSpacing;
	cursor: pointer;
}

// @if ($highlightBg == $checkboxActiveBg) {
// 	.p-highlight {
// 		.p-checkbox {
// 			.p-checkbox-box {
// 				border-color: $checkboxIconActiveColor;
// 			}
// 		}
// 	}
// }

//add
.checkbox-col-group {
	display: flex;
	flex-direction: column;
	p-checkbox {
		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}
	}
}
