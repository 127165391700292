$scroll-width: 12px;
$scroll-width-large: 12px;
$scroll-min-size: 24px;
$space: 4px;

.CodeMirror-simplescroll-horizontal div,
.CodeMirror-simplescroll-vertical div {
	transition-property: all;
	transition-duration: 0.15s;
	transition-timing-function: ease-in-out;
	position: absolute;
	border-radius: 100px;
	border: $space solid transparent;
	cursor: pointer;
	pointer-events: auto;
	background: var(--color-form-30);
	background-clip: content-box;
	box-sizing: border-box;
	transition-property: width, height;
	opacity: 0.6;

	&:hover {
		opacity: 0.64;
	}

	&:active {
		opacity: 0.84;
	}
}

.CodeMirror-simplescroll-vertical:hover div,
.CodeMirror-simplescroll-vertical div:active {
	width: $scroll-width-large;
}

.CodeMirror-simplescroll-horizontal,
.CodeMirror-simplescroll-vertical {
	position: absolute;
	z-index: 6;
}

.CodeMirror-simplescroll-horizontal {
	bottom: 0;
	left: 0;
	height: $scroll-width;
}

.CodeMirror-simplescroll-horizontal div {
	bottom: 0;
	height: 100%;
}

.CodeMirror-simplescroll-vertical {
	right: 0;
	top: 0;
	width: $scroll-width;
}

.CodeMirror-simplescroll-vertical div {
	right: 0;
	width: $scroll-width;
	min-height: $scroll-min-size;
}

.CodeMirror-simplescroll .CodeMirror-scrollbar-filler {
	z-index: 0;
}
