.p-tabview-nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex-wrap: wrap;
}

.p-tabview-nav-link {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    overflow: hidden;
}

.p-tabview-ink-bar {
    display: none;
    z-index: 1;
}

.p-tabview-nav-link:focus {
    z-index: 1;
}

.p-tabview-title {
    line-height: 1;
}

.p-tabview-close {
    z-index: 1;
}


.p-tabview {
    .p-tabview-nav {
        // background: $tabviewNavBg;
        border: 1px solid var(--color-layout-line-2);
        border-width: 0 0 1px 0;
        //add
        padding-bottom: 0.125rem;
        font-size: 0.813rem;
        font-weight: 600;
        line-height: 1rem;
        text-transform: uppercase;

        li {
            margin-right: 1.25rem;

            .p-tabview-nav-link {
                border: solid;
                border-width: 0 0 0.125rem 0;
                border-color: transparent;
                background: inherit;
                color: var(--color-text-secondary);
                padding: 0.75rem 0.25rem 0.625rem;
                font-weight: $tabviewHeaderFontWeight;
                // border-top-right-radius: $borderRadius;
                // border-top-left-radius: $borderRadius;
                transition: $listItemTransition;
                margin: 0 0 -0.125rem 0;

                &:not(.p-disabled):focus {
                    // @include focused();
                }
                .p-tabview-nav-count {
                    font-weight: normal;
                    border-radius: 0.5rem;
                    background-color: var(--color-icon-secondary);
                    color: var(--color-text-white);
                    margin-left: 0.5rem;
                    padding: 0 0.25rem;
                }
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                .p-tabview-nav-link {
                    background: inherit;
                    // border-color: var(--color-form-40);
                    color: var(--color-text-main);
                }
            }

            &.p-highlight {
                .p-tabview-nav-link {
                    background: inherit;
                    border-color: $tabviewHeaderActiveBorderColor;
                    color: var(--color-text-main);
                }
            }
        }
    }

    .p-tabview-left-icon {
        margin-right: $inlineSpacing;
    }

    .p-tabview-right-icon {
        margin-left: $inlineSpacing;
    }

    .p-tabview-close {
        margin-left: $inlineSpacing;
    }

    .p-tabview-panels {
        background: $tabviewContentBg;
        padding: $tabviewContentPadding;
        border: $tabviewContentBorder;
        color: $tabviewContentTextColor;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }
}
