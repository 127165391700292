en-small {
	margin: 0.25rem 0 0 0;
}

.en-small {
	position: relative;
	padding: 0 0 0 1.25rem;
}

.en-small-icon {
	position: absolute;
	transform: translateY(-50%);
	left: 0;
	top: 50%;
}

.en-small-icon.en-icon-validate {
	color: var(--color-red-60);
}

.en-small-icon.en-icon-info {
	color: var(--color-blue-60);
}

.en-small-icon.en-icon-warning {
	color: var(--color-amber-60);
}

.en-small-detail {
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: var(--color-text-secondary);
}

.en-small-detail.en-small-detail--validate {
	color: var(--color-red-60);
}

.en-small-detail.en-small-detail--info {
	color: inherit;
}
