.en-dropdown {
	background: linear-gradient(
		180deg,
		var(--color-layout-white) 0,
		var(--color-form-20) 2rem,
		var(--color-form-30) 4rem,
		var(--color-form-30) 6rem
	);
	background-size: auto 6rem;
	border: 1px solid var(--color-form-30);
	transition: $formElementTransition, background 0.2s;
	border-radius: 2px;
	height: 2rem;

	.en-dropdown-label:not(.en-dropdown-clearable) {
		padding: 0 0.5rem 0 0.75rem;
	}

	.en-dropdown-label.en-dropdown-clearable {
		padding: 0 1.5rem 0 0.75rem;
	}
	.en-dropdown-clear-icon {
		right: 1.5rem;
	}

	&:not(.en-dropdown-disabled):hover {
		background-position: 4rem;
	}

	&:not(.en-dropdown-disabled).en-focus {
		@include focused-input();
	}

	&.en-dropdown-iconable {
		.en-dropdown-label {
			padding-left: 2rem;
		}
	}

	.en-dropdown-label {
		background: transparent;
		border: 0 none;
		line-height: 1.875rem;
		height: 100%;

		&.en-placeholder {
			color: var(--color-text-main);
		}

		&:enabled:focus {
			outline: 0 none;
			box-shadow: none;
		}
	}

	.en-dropdown-trigger {
		background: transparent;
		color: $inputIconColor;
		padding-right: 0.5rem;
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}

	.en-dropdown-icon {
		position: absolute;
		top: 50%;
		left: 0.75rem;
		transform: translateY(-50%);
	}
}

.en-dropdown-disabled {
	cursor: default !important;
	pointer-events: none;

	border: 1px solid var(--color-form-20);
	background: linear-gradient(
		180deg,
		var(--color-layout-white) 0,
		var(--color-form-10) 2.5rem
	);

	.en-dropdown-trigger .en,
	.en-dropdown-icon.en {
		color: var(--color-icon-disable);
	}
	.en-dropdown-label,
	.en-dropdown-label .en {
		color: var(--color-text-disable);
	}
	.en-dropdown-label.en-placeholder {
		color: var(--color-text-disable);
	}
}

@mixin h36 {
	height: 2.25rem;
	.en-dropdown-label:not(.en-dropdown-clearable) {
		padding: 0 0.5rem 0 0.75rem;
		line-height: 2.125rem;
		height: 100%;
	}

	.en-dropdown-label.en-dropdown-clearable {
		padding: 0 1.375rem 0 0.75rem;
		line-height: 2.125rem;
	}
	.en-dropdown-clear-icon {
		right: 1.7rem;
	}

	&.en-dropdown-iconable {
		.en-dropdown-label {
			padding-left: 2rem;
		}
	}

	.en-dropdown-trigger {
		padding-right: 0.75rem;
	}

	.en-dropdown-icon {
		position: absolute;
		top: 50%;
		left: 0.75rem;
		transform: translateY(-50%);
	}
}

@mixin h28 {
	height: 1.75rem;

	.en-dropdown-label {
		padding: 0 0.25rem 0 0.5rem;
		line-height: 1.625rem;
		height: 100%;
	}

	.en-dropdown-label.en-dropdown-clearable {
		padding: 0 1.625rem 0 0.75rem;
	}
	.en-dropdown-clear-icon {
		right: 2rem;
	}

	&.en-dropdown-iconable {
		.en-dropdown-label {
			padding-left: 2rem;
		}
	}

	.en-dropdown-trigger {
		padding-right: 0.5rem;
		line-height: 1.625rem;
	}

	.en-dropdown-icon {
		position: absolute;
		top: 50%;
		left: 0.5rem;
		transform: translateY(-50%);
	}
}

.en-dropdown.h36 {
	background-size: auto 6.75rem;
	@include h36;

	&:not(.en-dropdown-disabled):hover {
		background-position: 4.5rem;
	}

	&.en-dropdown-iconable {
		.en-dropdown-label {
			padding-left: 2.25rem;
		}
	}
}

.en-dropdown.h28 {
	background-size: auto 5.25rem;
	@include h28;

	&:not(.en-dropdown-disabled):hover {
		background-position: 3.5rem;
	}

	&.en-dropdown-iconable {
		.en-dropdown-label {
			padding-left: 2rem;
		}
	}
}

.en-dropdown-lowercase {
	border: 1px solid transparent;
	background: var(--color-layout-white);

	&:not(.en-dropdown-disabled):hover {
		background: var(--color-form-10);
	}

	&:not(.en-dropdown-disabled).en-focus {
		outline: none;
		box-shadow: none;
		border-color: transparent;
		background: var(--color10);
	}

	&.h36 {
		@include h36;
	}
	&.h28 {
		@include h28;
	}
}

.en-dropdown-solid {
	background: var(--color-layout-white);

	& > .en-dropdown-label.en-placeholder {
		color: var(--color-icon-secondary);
	}

	&:not(.en-dropdown-disabled):not(.en-focus):hover {
		border-color: var(--color-form-40);
	}

	&.h36 {
		@include h36;
	}
	&.h28 {
		@include h28;
	}

	&.en-dropdown-disabled {
		background: var(--color-layout-white);
		border-color: var(--color-form-20);
	}
}

.en-dropdown-filter {
	background: none;
	border: 1px solid transparent;
	&:not(.en-dropdown-disabled):not(.en-focus):hover {
	}

	&:not(.en-dropdown-disabled).en-focus {
		outline: none;
		box-shadow: none;
		border-color: transparent;
		background: var(--color-blue-10);
	}

	.en-dropdown-label {
		color: var(--color-blue-60);
	}

	.en-dropdown-label.en-placeholder {
		color: var(--color-blue-60);
	}

	.en-dropdown-trigger-icon {
		color: var(--color-blue-60);
	}

	&.h36 {
		@include h36;
	}

	&.h28 {
		@include h28;
	}

	&:hover {
		background: var(--color-form-10);
	}
}

.en-dropdown-panel {
	background: $inputOverlayBg;
	color: $inputListTextColor;
	border: $inputOverlayBorder;
	border-radius: 4px;
	box-shadow: var(--shadow-popover);

	.en-dropdown-header {
		padding: 0.75rem 1rem;
		border-bottom: 1px solid var(--color-layout-line-1);
		color: $inputListHeaderTextColor;
		background: var(--color-layout-background);
		margin: $inputListHeaderMargin;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;

		.en-dropdown-filter {
			// padding-right: nth($inputPadding, 2) + $primeIconFontSize;
		}

		.en-dropdown-filter-icon {
			right: nth($inputPadding, 2);
			color: $inputIconColor;
		}
	}

	.en-dropdown-items {
		padding: 0.5rem 0;

		.en-dropdown-item {
			margin: $inputListItemMargin;
			padding: 0.5rem 1rem;
			border: $inputListItemBorder;
			color: var(--color-text-main);
			background: $inputListItemBg;
			transition: $listItemTransition;
			border-radius: $inputListItemBorderRadius;
			line-height: 1rem;

			&.en-highlight {
				color: var(--color-text-main);
				background: var(--color-form-10);
				font-weight: 500;
			}

			&:not(.en-highlight):not(.en-dropdown-option-disabled):hover {
				color: var(--color-text-main);
				background: var(--color-form-10);
			}
		}

		.en-dropdown-item-group {
			margin: $submenuHeaderMargin;
			padding: $submenuHeaderPadding;
			color: $submenuHeaderTextColor;
			background: $submenuHeaderBg;
			font-weight: $submenuHeaderFontWeight;
		}

		.en-dropdown-option-disabled {
			color: var(--color-text-disable);
		}
	}

	&.en-error,
	&.en-invalid {
		@include invalid-input();
	}
}

en-dropdown.ng-dirty.ng-invalid > .en-dropdown {
	@include invalid-input();
}

.en-input-filled {
	.en-dropdown {
		background: $inputFilledBg;

		&:not(.en-disabled):hover {
			background-color: $inputFilledHoverBg;
		}

		&:not(.en-disabled).en-focus {
			background-color: $inputFilledFocusBg;
		}
	}
}
