.text-gray-badge {
	background: var(--color-form-10);
	border-radius: 2px;
	padding: 0 0.75rem;
	height: 2rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	&.contains-id {
		padding: 0 0.75rem;
	}
}

.text-gray-badge > .en {
	margin-right: 0.25rem;
}

.close-icon-badge {
	margin-left: 0.125rem;
	cursor: pointer;
}

.cyan-badge {
	border: 1px solid var(--color-blue-60);
	background-color: var(--color-blue-10);
	height: 1.25rem;
	border-radius: 4px;
	font-size: 0.75rem;
	padding: 0 0.25rem;
	display: inline-flex;
	align-items: center;
	flex-shrink: 0;

	&.disabled {
		background: var(--color-form-10);
		border-color: var(--color-form-20);
	}
}

.id-badge {
	border: 1px solid var(--color-form-20);
	background-color: var(--color-form-10);
	height: 1.25rem;
	border-radius: 4px;
	font-size: 0.75rem;
	padding: 0 0.25rem;
	display: inline-flex;
	align-items: center;
	flex-shrink: 0;
}

.id-label {
	border: 1px solid var(--color-form-40);
	background-color: var(--color-form-10);
	display: inline-flex;
	align-items: center;
	border-radius: 4px;
	text-align: center;
	font-size: 0.813rem;
	margin: 0 0.75rem 0.75rem 0;
}

.id-label__id {
	border-right: 1px solid var(--color-form-40);
}

.id-label__name,
.id-label__id {
	height: 1.75rem;
	padding: 0 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

// Бадж для каналов в таблицах
.channel {
	display: flex;
	flex-wrap: wrap;
	margin-top: -0.25rem;
	padding: 0.5rem 0;
}

.channel__badge {
	position: relative;
	background: var(--color-form-10);
	border-radius: 4px;
	padding: 0.25rem 0.5rem 0.25rem 1.75rem;
	margin-right: 0.5rem;
	margin-top: 0.25rem;
}

.channel__icon {
	position: absolute;
	top: 0.25rem;
	left: 0.25rem;
}
