/* Inter-regular - latin_cyrillic */
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	src: local('Inter'), local('Inter-Regular'),
		url('/assets/fonts/main/Inter-Regular-400.woff2') format('woff2'),
		url('/assets/fonts/main/Inter-Regular-400.woff') format('woff');
}

/* Inter-500 - latin_cyrillic */
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	src: local('Inter Medium'), local('Inter-Medium'),
		url('/assets/fonts/main/Inter-Medium-500.woff2') format('woff2'),
		url('/assets/fonts/main/Inter-Medium-500.woff') format('woff');
}

/* Inter-600 - latin_cyrillic */
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	src: local('Inter Bold'), local('Inter-Bold'),
		url('/assets/fonts/main/Inter-SemiBold-600.woff2') format('woff2'),
		url('/assets/fonts/main/Inter-SemiBold-600.woff') format('woff');
}
