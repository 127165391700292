// Theme Specific Variables
$primaryColor: var(--color60);
$primaryLightColor: var(--color50);
$primaryDarkColor: var(--color70);
$primaryDarkerColor: var(--color80);
$primaryTextColor: $color-layout-white;
$inputerrorbordercolor: $color-red-60;
$fontFamily: var(--en-font-family);

// Mandatory Designer Variables
@import 'src/styles/prime/variables/general';
@import 'src/styles/prime/variables/form';
@import 'src/styles/prime/variables/button';
@import 'src/styles/prime/variables/panel';
@import 'src/styles/prime/variables/_data';
@import 'src/styles/prime/variables/_overlay';
@import 'src/styles/prime/variables/_message';
@import 'src/styles/prime/variables/_menu';
@import 'src/styles/prime/variables/_media';
@import 'src/styles/prime/variables/_misc';
