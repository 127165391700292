.en-radiobutton {
	width: $radiobuttonWidth;
	height: $radiobuttonHeight;

	.en-radiobutton-box {
		border: $radiobuttonBorder;
		background: $inputBg;
		width: $radiobuttonWidth;
		height: $radiobuttonHeight;
		color: $textColor;
		border-radius: 50%;
		transition: $formElementTransition;

		&:not(.en-disabled):not(.en-highlight):hover {
			border-color: $inputHoverBorderColor;
		}

		&:not(.en-disabled).en-focus {
			@include focused-input();
		}

		.en-radiobutton-icon {
			width: $radiobuttonIconSize;
			height: $radiobuttonIconSize;
			transition-duration: $transitionDuration;
			background-color: $radiobuttonIconActiveColor;
		}

		&.en-highlight {
			border-color: $radiobuttonActiveBorderColor;
			background: $radiobuttonActiveBg;

			&:not(.en-disabled):hover {
				border-color: $radiobuttonActiveHoverBorderColor;
				background: $radiobuttonActiveHoverBg;
				color: $radiobuttonIconActiveHoverColor;
			}
		}
	}

	&.en-error > .en-radiobutton-box,
	&.en-invalid > .en-radiobutton-box {
		@include invalid-input();
	}
}

en-radiobutton.ng-dirty.ng-invalid > .en-radiobutton > .en-radiobutton-box {
	@include invalid-input();
}

.en-input-filled {
	.en-radiobutton {
		.en-radiobutton-box {
			background-color: $inputFilledBg;

			&:not(.en-disabled):hover {
				background-color: $inputFilledHoverBg;
			}

			&.en-highlight {
				background: $radiobuttonActiveBg;

				&:not(.en-disabled):hover {
					background: $radiobuttonActiveHoverBg;
				}
			}
		}
	}
}

.en-radiobutton-label {
	margin-left: 0.5rem;
}

@if ($highlightBg == $radiobuttonActiveBg) {
	.en-highlight {
		.en-radiobutton {
			.en-radiobutton-box {
				border-color: $radiobuttonIconActiveColor;
			}
		}
	}
}

//add стили которые должны идти от прайма
.en-radiobutton {
	display: inline-flex;
	cursor: pointer;
	user-select: none;
	vertical-align: bottom;
}

.en-radiobutton-box {
	display: flex;
	justify-content: center;
	align-items: center;
}

.en-radiobutton-icon {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform: translateZ(0) scale(0.1);
	border-radius: 50%;
	visibility: hidden;
}

.en-radiobutton-box.en-highlight .en-radiobutton-icon {
	transform: translateZ(0) scale(1, 1);
	visibility: visible;
}

en-radiobutton {
	display: inline-flex;
	vertical-align: bottom;
	align-items: center;
}

.en-radiobutton-label {
	line-height: 1;
}
