@use 'sass:math';

.en-inputswitch {
	.en-inputswitch-slider {
		background: $inputSwitchSliderOffBg;
		transition: $formElementTransition;
		border-radius: $inputSwitchBorderRadius;

		&:before {
			background: $inputSwitchHandleOffBg;
			width: $inputSwitchHandleWidth;
			height: $inputSwitchHandleHeight;
			left: $inputSwitchSliderPadding;
			margin-top: math.div(-1 * $inputSwitchHandleHeight, 2);
			border-radius: $inputSwitchHandleBorderRadius;
		}
	}

	&.en-inputswitch-checked {
		.en-inputswitch-slider {
			background: $inputSwitchSliderOnBg;

			&:before {
				background: $inputSwitchHandleOnBg;
			}
		}
	}

	&.en-error,
	&.en-invalid {
		@include invalid-input();
	}
}

p-inputswitch.ng-dirty.ng-invalid > .en-inputswitch {
	@include invalid-input();
}
