p-multiselect {
	display: inline-flex;
}
.p-multiselect {
	background: $inputBg;
	border: 1px solid var(--color-form-30);
	transition: $formElementTransition;
	border-radius: $borderRadius;

	// //add
	min-width: 17.5rem;
	min-height: 2rem;

	&:not(.p-disabled):hover {
		border-color: var(--color-form-40);
	}

	&:not(.p-disabled).p-focus {
		@include focused-input();
	}

	.p-multiselect-label-container {
		display: flex;
		align-items: center;
	}

	.p-multiselect-label {
		padding: 0 0.25rem 0 0.5rem;
		transition: $formElementTransition;
		line-height: 1.875rem;

		&.p-placeholder {
			color: $inputPlaceholderTextColor;
		}
	}

	.p-multiselect-trigger {
		background: transparent;
		color: $inputIconColor;
		padding-right: 0.5rem;
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}

	&.p-error,
	&.p-invalid {
		@include invalid-input();
	}
}

.p-multiselect-panel {
	background: $inputOverlayBg;
	color: $inputListTextColor;
	border: $inputOverlayBorder;
	border-radius: 4px;
	box-shadow: $inputOverlayShadow;

	.p-multiselect-header {
		padding: 0.75rem 0.75rem;
		border-bottom: $inputListHeaderBorder;
		color: $inputListHeaderTextColor;
		background: $inputOverlayHeaderBg;
		margin: $inputListHeaderMargin;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;

		.p-multiselect-filter-container {
			.p-inputtext {
				padding-right: nth($inputPadding, 2) + $primeIconFontSize;
			}

			.p-multiselect-filter-icon {
				right: nth($inputPadding, 2);
				color: $inputIconColor;
			}
		}

		.p-checkbox {
			margin-right: 0.5rem;
		}

		.p-multiselect-close {
			margin-left: 0.5rem;
			// @include action-icon();
		}
	}

	.p-multiselect-items {
		padding: 0.5rem 0;

		.p-multiselect-item {
			height: 2rem;
			margin: $inputListItemMargin;
			padding: 0.5rem 0.75rem;
			border: $inputListItemBorder;
			color: $inputListItemTextColor;
			background: $inputListItemBg;
			transition: $listItemTransition;
			border-radius: $inputListItemBorderRadius;

			&.p-highlight {
				color: var(--color-text-main);
				background: var(--color-form-10);
				font-weight: 500;
			}

			&:not(.p-highlight):not(.p-disabled):hover {
				color: var(--color-text-main);
				background: var(--color-form-10);
			}

			&:focus {
				@include focused-listitem();
			}

			.p-checkbox {
				margin-right: 0.5rem;
			}
		}
	}
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
	@include invalid-input();
}

.p-input-filled {
	.p-multiselect {
		background: $inputFilledBg;

		&:not(.p-disabled):hover {
			background-color: $inputFilledHoverBg;
		}

		&:not(.p-disabled).p-focus {
			background-color: $inputFilledFocusBg;
		}
	}
}
