.p-component {
	font-family: $fontFamily;
	font-size: $fontSize;
	font-weight: $fontWeight;
}

.p-component-overlay {
	background-color: rgba(255, 255, 255, 0.5);
	transition-duration: $transitionDuration;
}

.p-disabled,
.p-component:disabled {
	opacity: 0.6;
}

.p-error,
.p-invalid {
	color: $errorColor;
}

.p-text-secondary {
	color: $textSecondaryColor;
}

.pi {
	font-size: $primeIconFontSize;
}

.p-link {
	font-size: $fontSize;
	font-family: $fontFamily;
	border-radius: $borderRadius;

	&:focus {
		@include focused();
	}
}

.p-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.p-hidden-accessible input,
.p-hidden-accessible select {
	transform: scale(0);
}
