// $fontFamily: 'Inter', sans-serif;

*,
::after,
::before {
	box-sizing: border-box;
}

.app {
	--en-font-family: 'Inter', sans-serif;
	font-family: var(--en-font-family);
	font-size: 16px;
}

.app__body {
	margin: 0 auto;
	font-size: 0.875rem;
	background-color: var(--color-layout-white);
	color: var(--color-text-main);
	overflow-y: hidden;
}

.app,
.app__body {
	height: 100%;
}
