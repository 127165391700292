.en-autocomplete {
	// &.en-focus {
	//     @include focused-input();
	// }
	.en-autocomplete-loader {
		right: nth($inputPadding, 2);
	}

	&.en-autocomplete-dd {
		.en-autocomplete-loader {
			right: nth($inputPadding, 2) + $buttonIconOnlyWidth;
		}
	}

	&.en-autocomplete-multiple {
		.en-autocomplete-multiple-container {
			padding: 0 4rem 0 0.5rem;
			min-height: 2rem;

			&:not(.en-disabled):hover {
				border-color: $inputHoverBorderColor;
			}

			&:not(.en-disabled).en-focus {
				@include focused-input();
			}

			.en-autocomplete-input-token {
				// padding: nth($inputPadding, 1)/2 0;

				input {
					font-family: $fontFamily;
					font-size: $fontSize;
					color: $textColor;
					padding: 0.25rem 0;
					margin: 0;
				}
			}

			.en-autocomplete-token {
				padding: 0.25rem 0.25rem 0.25rem 0.5rem;
				margin: 0.125rem 0.25rem 0.125rem 0;
				// background: $highlightBg;
				// color: $highlightTextColor;
				border-radius: $borderRadius;

				.en-autocomplete-token-icon {
					margin-left: 0.5rem;
				}
			}
		}
	}

	&.en-error > .en-inputtext,
	&.en-invalid > .en-inputtext {
		@include invalid-input();
	}
}

en-autocomplete.ng-dirty.ng-invalid > .en-autocomplete > .en-inputtext {
	@include invalid-input();
}

.en-autocomplete-panel {
	background: $inputOverlayBg;
	color: $inputListTextColor;
	border: $inputOverlayBorder;
	border-radius: $borderRadius;
	box-shadow: var(--shadow-panel);

	.en-autocomplete-items {
		padding: 0.5rem 0;
		border-radius: 4px;

		.en-autocomplete-item {
			margin: $inputListItemMargin;
			padding: 0.5rem 0.75rem;
			border: $inputListItemBorder;
			color: $inputListItemTextColor;
			background: $inputListItemBg;
			transition: $listItemTransition;
			border-radius: $inputListItemBorderRadius;

			&:hover {
				color: $inputListItemTextHoverColor;
				background: $inputListItemHoverBg;
			}

			&.en-highlight {
				color: $highlightTextColor;
				background: $highlightBg;
			}
		}
	}
}
