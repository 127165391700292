.en-toast {
	position: fixed;
	display: flex;
	flex-direction: column;
}

.en-toast-message {
	overflow: hidden;
}

.en-toast-message-content {
	display: flex;
	align-items: flex-start;
}

.p-toast-message-text {
	flex: 1 1 auto;
}

.en-toast-top-right {
	top: 20px;
	right: 40px;
}

.en-toast-top-left {
	top: 20px;
	left: 20px;
}

.en-toast-bottom-left {
	bottom: 20px;
	left: 20px;
}

.en-toast-bottom-right {
	bottom: 20px;
	right: 20px;
}

.en-toast-top-center {
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	align-items: center;
}

.en-toast-bottom-center {
	bottom: 20px;
	left: 50%;
	margin-left: -10em;
}

.en-toast-center {
	left: 50%;
	top: 50%;
	min-width: 20vw;
	transform: translate(-50%, -50%);
}
